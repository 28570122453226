import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import Pagination from '../../components/pagination/Pagination';
import { axiosInstance, Service } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';

const Services = () => {

    const { authData } = useContext(AuthContext);
    const [services, setServices] = useState([]);
    const [filtredServices, setFiltredServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idServiceDelete, setIdServiceDelete] = useState('');

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredServices].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredServices(sortedTable);
    };

    //Ajouter service
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
    };

    const formik1 = useFormik({
        initialValues: {
            libelle: '',
            code: '',
        },
        validationSchema: Yup.object({
            libelle: Yup.string()
                .required('Champ obligatoire'),
            code: Yup.string()
                .required('Champ obligatoire'),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    libelle: values.libelle,
                    code: values.code,
                    idCompteCrud: authData.idCompte
                };
                //console.log(values)
                const response = await axiosInstance.post(Service, postData);
                CustomToast("Le service a été créé avec succès", 'success');
                getServices();
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Service existe déjà.") {
                    CustomToast("Service existe déjà.", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Code existe déjà.") {
                    CustomToast("Code existe déjà.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier service
    const showHideUpdateModal = (val, idService, libelle, code) => {
        setShowUpdateModal(val);
        formik2.resetForm();
        if (val) {
            formik2.setValues((prevValues) => ({
                ...prevValues,
                idService: idService,
                libelleUpdate: libelle,
                codeUpdate: code
            }));
        }
    };

    const formik2 = useFormik({
        initialValues: {
            idService: '',
            libelleUpdate: '',
            codeUpdate: ''
        },
        validationSchema: Yup.object({
            libelleUpdate: Yup.string()
                .required('Champ obligatoire'),
            codeUpdate: Yup.string()
                .required('Champ obligatoire'),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idService: values.idService,
                    libelle: values.libelleUpdate,
                    code: values.codeUpdate,
                    idCompteCrud: authData.idCompte
                };

                const response = await axiosInstance.put(Service, postData);
                CustomToast("Le service a été modifié avec succès", 'success');
                getServices();
                setShowUpdateModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Service existe déjà.") {
                    CustomToast("Service existe déjà.", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Code existe déjà.") {
                    CustomToast("Code existe déjà.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer un service
    const handleConfirmDelete = (idService) => {
        setIdServiceDelete(idService);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Service}/${idServiceDelete}`);
            setIdServiceDelete('');
            CustomToast("Le service a été supprimé avec succès", 'success');
            getServices();
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Service introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data

    const getServices = async () => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            setLoading(true);
            const response = await axiosInstance.get(`${Service}`);
            const resp = await response.data;
            setServices(resp);
            setFiltredServices(resp);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            // setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const handleSearch = (searchTerm) => {

        const result = services?.filter((item) =>
            Object.values(item).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFiltredServices(result);
        setSearch(searchTerm);
    };

    useEffect(() => {
        //Load Data
        getServices();

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        handleSearch(debouncedSearchText);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedSearchText]);

    return (
        <>
            <div className="container service-section">
                <div className="affectation-sec wrap-all">
                    <Link href="#" className="utilisateur-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter service
                        </div>
                    </Link>
                    <div className="group-elements">
                        <div className="voir-elements">
                            {/* <p>voir</p> */}
                            {/* <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select> */}
                        </div>
                        <div className="search-element">
                            {/* <p>Rechercher</p> */}
                            <input
                                type="search"
                                value={search}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredServices?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Code</th>
                                    {/* <th>Prefixe</th> */}
                                    <th>Modifier</th>
                                    <th>Supprimer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredServices?.map((row) => (
                                    <tr key={row.idService}>
                                        <td>{row.libelle}</td>
                                        <td>{row.code}</td>
                                        {/* <td>{row.prefix}</td> */}
                                        <td className="txt-center" onClick={() => showHideUpdateModal(true, row.idService, row.libelle, row.code)}>
                                            <span className="material-symbols-outlined">edit
                                            </span></td>
                                        <td className="txt-center" onClick={() => handleConfirmDelete(row.idService)}>
                                            <span className="material-symbols-outlined">delete</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                </div>

            </div>
            {showAddModal && <div className="popcard-add" id="ajouter-service">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" d="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Ajouter service</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelle"
                                            name="libelle"
                                            style={(formik1.touched.libelle && formik1.errors.libelle) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.libelle}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.libelle && formik1.errors.libelle ? (
                                            <div className="invalid-feedback">{formik1.errors.libelle}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one">
                                        <label >Code<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="code"
                                            name="code"
                                            style={(formik1.touched.code && formik1.errors.code) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.code}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.code && formik1.errors.code ? (
                                            <div className="invalid-feedback">{formik1.errors.code}</div>
                                        ) : null}
                                    </div>
                                    {/* <div className="wrap-one">
                                        <label>Prefix<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="prefix"
                                            name="prefix"
                                            style={(formik1.touched.prefix && formik1.errors.prefix) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.prefix}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.prefix && formik1.errors.prefix ? (
                                            <div className="invalid-feedback">{formik1.errors.prefix}</div>
                                        ) : null}
                                    </div> */}
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            {showUpdateModal && <div className="popcard-add" id="modifier-service">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" d="close-vehc" to="#" onClick={() => showHideUpdateModal(false, '', '', '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Modifier service</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik2.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelleUpdate"
                                            name="libelleUpdate"
                                            style={(formik2.touched.libelleUpdate && formik2.errors.libelleUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.libelleUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.libelleUpdate && formik2.errors.libelleUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.libelleUpdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one">
                                        <label >Code<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="codeUpdate"
                                            name="codeUpdate"
                                            style={(formik2.touched.codeUpdate && formik2.errors.codeUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.codeUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.codeUpdate && formik2.errors.codeUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.codeUpdate}</div>
                                        ) : null}
                                    </div>
                                    {/* <div className="wrap-one">
                                        <label>Prefix<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="prefixUpdate"
                                            name="prefixUpdate"
                                            style={(formik2.touched.prefixUpdate && formik2.errors.prefixUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.prefixUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.prefixUpdate && formik2.errors.prefixUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.prefixUpdate}</div>
                                        ) : null}
                                    </div> */}
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer ce service ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer le service"
            />
        </>

    );
}

export default Services;
