import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

//const API_KEY = process.env.REACT_APP_API_KEY;

const BASE_URL_API = BASE_URL+'api/';

const IMAGE_URL = BASE_URL+'images/';

// Create an instance of Axios with the base URL
export const axiosInstance = axios.create({
    baseURL: BASE_URL_API,
  });

// Add request interceptor
axiosInstance.interceptors.request.use((config) => {
  // Add JWT token to the request header from localStorage
  const authData = JSON.parse(localStorage.getItem('authData'));
  const jwtToken = authData ? authData.token : null;
  if (jwtToken) {
    config.headers['Authorization'] = `Bearer ${jwtToken}`;
  }

  // Add API key to the request header
  //config.headers['X-Api-Key'] = API_KEY;
  //Implement api key expiration

  return config;
});

export {BASE_URL};

//API endpoints

export const Authentification=BASE_URL_API+'Authentification';
export const Utilisateur=BASE_URL_API+'Utilisateur';
export const Guichet=BASE_URL_API+'Guichet';
export const Materiel=BASE_URL_API+'Materiel';
export const Compte=BASE_URL_API+'Compte';
export const Agence=BASE_URL_API+'Agence';
export const FileAttenteParams=BASE_URL_API+'FileAttenteParams';
export const Service=BASE_URL_API+'Service';
export const Wilaya=BASE_URL_API+'Wilaya';
export const Commune=BASE_URL_API+'Commune';
export const Visiteur=BASE_URL_API+'Visiteur';
export const Stock=BASE_URL_API+'Stock';


//To display file ressources
export const ClientsFile=IMAGE_URL+'Clients/';

//WebSockets-SignalR
export const AppHub=BASE_URL+'appHub';

export const getJwtToken=()=> {
  // Implement your logic to retrieve the JWT token
  const authData = JSON.parse(localStorage.getItem('authData'));
  return (authData ? authData.token : null);
}

// export const DashboardHub=BASE_URL+'dashboardHub';

export const createCancelTokenSource = () => axios.CancelToken.source();