import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import Pagination from '../../components/pagination/Pagination';
import { axiosInstance, createCancelTokenSource, Utilisateur, Agence } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';
const Utilisateurs = () => {

    const { authData } = useContext(AuthContext);
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [filtredUtilisateurs, setFiltredUtilisateurs] = useState([]);
    const [agences, setAgences] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idUtilisateurDelete, setIdUtilisateurDelete] = useState('');
    const [idUtilisateurUpdate, setIdUtilisateurUpdate] = useState('');

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredUtilisateurs].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredUtilisateurs(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = utilisateurs.slice(startIndex, endIndex);

        setFiltredUtilisateurs(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Ajouter utilisateur
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
        formik1.values.statut = 'actif';
        getAgences()
    };

    const formik1 = useFormik({
        initialValues: {
            idAgence: '',
            email: '',
            username: '',
            password: '',
            statut: 'actif',
            nom: '',
            prenom: '',
            genre: '',
            role: ''
        },
        validationSchema: Yup.object({
            nom: Yup.string()
                .required('Champ obligatoire'),
            prenom: Yup.string()
                .required('Champ obligatoire'),
            genre: Yup.string()
                .required('Champ obligatoire'),
            idAgence: Yup.string()
                .required('Champ obligatoire'),
            statut: Yup.string()
                .required('Champ obligatoire'),
            role: Yup.string()
                .required('Champ obligatoire'),
            username: Yup.string().test('username-validation', 'Invalid username', function (value) {
                if (!value) {
                    return this.createError({ message: 'Champ obligatoire' });
                }
                if (value.length < 8 || value.length > 255) {
                    return this.createError({ message: 'Le champ doit contenir entre 8 et 255 caractères' });
                }

                return true;
            }),
            password: Yup.string().test('password-validation', 'Invalid password', function (value) {
                if (!value) {
                    return this.createError({ message: 'Champ obligatoire' });
                }
                if (value.length < 7 || value.length > 16) {
                    return this.createError({ message: 'Le champ doit contenir entre 7 et 16 caractères' });
                }

                return true;
            }),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idAgence: values.idAgence,
                    email: values.email,
                    username: values.username,
                    password: values.password,
                    statut: values.statut,
                    nom: values.nom,
                    prenom: values.prenom,
                    genre: values.genre,
                    role: values.role,
                    idCompteCrud: authData.idCompte
                };
                //console.log(values)
                const response = await axiosInstance.post(Utilisateur, postData);
                CustomToast("L'utilisateur a été créé avec succès", 'success');
                getUtilisateurs(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un compte avec le même nom d'utilisateur existe déjà.") {
                    CustomToast("Un utilisateur avec le même nom d'utilisateur existe déjà.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier utilisateur
    const showHideUpdateModal = (val, idUtilisateur, idCompte, idAgence, username, statut, nom, prenom, genre, role) => {
        setShowUpdateModal(val);
        formik2.resetForm();
        getAgences();
        if (val) {
            formik2.setValues((prevValues) => ({
                ...prevValues,
                idUtilisateurUpdate: idUtilisateur,
                idCompteUpdate: idCompte,
                idAgenceUpdate: idAgence,
                usernameUpdate: username,
                statutUpdate: statut || 'actif',
                nomUpdate: nom,
                prenomUpdate: prenom,
                genreUpdate: genre,
                roleUpdate: role,
            }));

        }
    };

    const handleResetPassword = () => {
        formik2.setFieldValue('resetPassword', !formik2.values.resetPassword);
    };

    const formik2 = useFormik({
        initialValues: {
            idUtilisateurUpdate: '',
            idCompteUpdate: '',
            idAgenceUpdate: '',
            emailUpdate: '',
            usernameUpdate: '',
            resetPassword: false,
            newPassword: '',
            statutUpdate: '',
            nomUpdate: '',
            prenomUpdate: '',
            genreUpdate: '',
            roleUpdate: ''
        },
        validationSchema: Yup.object({
            nomUpdate: Yup.string()
                .required('Champ obligatoire'),
            prenomUpdate: Yup.string()
                .required('Champ obligatoire'),
            genreUpdate: Yup.string()
                .required('Champ obligatoire'),
            idAgenceUpdate: Yup.string()
                .required('Champ obligatoire'),
            statutUpdate: Yup.string()
                .required('Champ obligatoire'),
            roleUpdate: Yup.string()
                .required('Champ obligatoire'),
            resetPassword: Yup.bool(),
            usernameUpdate: Yup.string().test('username-validation', 'Invalid username', function (value) {
                if (!value) {
                    return this.createError({ message: 'Champ obligatoire' });
                }
                if (value.length < 8 || value.length > 255) {
                    return this.createError({ message: 'Le champ doit contenir entre 8 et 255 caractères' });
                }

                return true;
            }),
            newPassword: Yup.string().test('password-validation', 'Invalid password', function (value) {
                const resetPassword = this.resolve(Yup.ref('resetPassword'));

                if (resetPassword) {
                    if (!value) {
                        return this.createError({ message: 'Champ obligatoire' });
                    }
                    if (value.length < 7 || value.length > 16) {
                        return this.createError({ message: 'Le champ doit contenir entre 7 et 16 caractères' });
                    }
                }

                return true;
            }),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idUtilisateur: values.idUtilisateurUpdate,
                    idCompte: values.idCompteUpdate,
                    idAgence: values.idAgenceUpdate,
                    email: values.emailUpdate,
                    username: values.usernameUpdate,
                    resetPassword: values.resetPassword,
                    newPassword: values.newPassword,
                    statut: values.statutUpdate,
                    nom: values.nomUpdate,
                    prenom: values.prenomUpdate,
                    genre: values.genreUpdate,
                    role: values.roleUpdate,
                    idCompteCrud: authData.idCompte
                };

                const response = await axiosInstance.put(Utilisateur, postData);
                CustomToast("L'utilisateur a été modifié avec succès", 'success');
                getUtilisateurs(pageNumber, pageSize);
                setShowUpdateModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un compte avec le même nom d'utilisateur existe déjà.") {
                    CustomToast("Un utilisateur avec le même nom d'utilisateur existe déjà.", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Le compte à mettre à jour n'existe pas.") {
                    CustomToast("Le compte à mettre à jour n'existe pas.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer  un Utilisateur
    const handleConfirmDelete = (idUtilisateur) => {
        setIdUtilisateurDelete(idUtilisateur);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Utilisateur}/${idUtilisateurDelete}`);
            //getTypesDepenses();
            setIdUtilisateurDelete('');
            CustomToast("L'utilisateur a été supprimé avec succès", 'success');
            getUtilisateurs(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Utilisateur introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data

    const getUtilisateurs = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }
//console.log(authData.idAgence )
            // if (authData?.role != null && authData?.role !== "admin") {
            //     if (authData.idAgence == null || authData.idAgence === "") {
            //         return;
            //     }
            //     queryParams.append("idAgence", authData?.idAgence);
            // }

            if (authData?.role != null  && authData?.role !== "") {
                if (authData?.role !== "admin") {
                    queryParams.append("idAgence", authData?.idAgence);
                }                
            }else{
                return;
            }

            const response = await axiosInstance.get(`${Utilisateur}/GetUtilisateursListe?${queryParams}`,{ cancelToken });
            const resp = await response.data;
            setUtilisateurs(resp.data);
            setFiltredUtilisateurs(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            // setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const getAgences = async () => {
        try {
            if (authData?.role != null && authData?.role === "admin") {
                const response = await axiosInstance.get(`${Agence}`);
                const resp = await response.data;
                setAgences(resp);
            } else {
                const queryParams = new URLSearchParams({
                    idAgence: authData.idAgence
                });
                const response = await axiosInstance.get(`${Agence}?${queryParams}`);
                const resp = await response.data;
                formik1.setValues((prevValues) => ({
                    ...prevValues,
                    idAgence: authData.idAgence
                }));
                setAgences(resp);
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getUtilisateurs(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getUtilisateurs(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getUtilisateurs(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);

    return (
        <>
            <div className="container user-section">
                <div className="affectation-sec wrap-all">
                    <Link href="#" className="utilisateur-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter utilisateur
                        </div>
                    </Link>
                    <div className="group-elements">
                        <div className="voir-elements">
                            {/* <p>voir</p> */}
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            {/* <p>Rechercher</p> */}
                            <input
                                type="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredUtilisateurs?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    {authData?.role != null && authData?.role === "admin" && <th>Agence</th>}
                                    <th>Username</th>
                                    <th>Rôle</th>
                                    <th>Statut</th>
                                    <th>Modifier</th>
                                    <th>Supprimer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredUtilisateurs?.map((row) => (
                                    <tr key={row.idUtilisateur}>
                                        <td>{row.nom}</td>
                                        <td>{row.prenom}</td>
                                        {authData?.role != null && authData?.role === "admin" && <td>{row.libelleAgence}</td>}
                                        <td>{row.username}</td>
                                        <td>{
                                            row.role === "admin" ? <>Admin</> :
                                                row.role === "agent guichet" ? <>Agent guichet</> :
                                                    row.role === "chef agence" ? <>Chef agence</> :
                                                        row.role === "gestionnaire stock" ? <>Gestionnaire stock</> :
                                                            null
                                        }</td>
                                        <td>{
                                            row.statut === "actif" ? <>Actif</> :
                                                row.statut === "inactif" ? <>Inactif</> :
                                                    null
                                        }</td>
                                        <td className="txt-center" onClick={() => showHideUpdateModal(true, row.idUtilisateur, row.idCompte, row.idAgence, row.username, row.statut, row.nom, row.prenom, row.genre, row.role)}>
                                            <span className="material-symbols-outlined">edit
                                            </span></td>
                                        <td className="txt-center" onClick={() => handleConfirmDelete(row.idUtilisateur)}>
                                            <span className="material-symbols-outlined">delete</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {/* <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Link
                                key={i + 1}
                                to="#"
                                className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Link>
                        ))}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                            disabled={((pageNumber + 1) > totalPages) ? true : false}
                        >&raquo;</Link>
                    </div> */}
                    {filtredUtilisateurs?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>

            </div>
            {showAddModal && <div className="popcard-add" id="ajouter-utilisateur">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" d="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Ajouter utilisateur</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <label >Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="nom"
                                            name="nom"
                                            style={(formik1.touched.nom && formik1.errors.nom) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.nom}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.nom && formik1.errors.nom ? (
                                            <div className="invalid-feedback">{formik1.errors.nom}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one">
                                        <label >Prénom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="prenom"
                                            name="prenom"
                                            style={(formik1.touched.prenom && formik1.errors.prenom) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.prenom}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.prenom && formik1.errors.prenom ? (
                                            <div className="invalid-feedback">{formik1.errors.prenom}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Agence<span className='requiredField'>*</span></label>
                                        <select
                                            id="idAgence"
                                            name="idAgence"
                                            value={formik1.values.idAgence}
                                            onChange={formik1.handleChange}
                                            style={(formik1.touched.idAgence && formik1.errors.idAgence) ? { borderColor: "red" } : null}
                                        >
                                            {authData?.role != null && authData?.role === "admin" && <option value="" defaultValue={true}>Veuillez sélectionner</option>}
                                            {agences.map((agence) => (
                                                <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idAgence && formik1.errors.idAgence ? (
                                            <div className="invalid-feedback">{formik1.errors.idAgence}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label >Genre<span className='requiredField'>*</span></label>
                                        <select
                                            id="genre"
                                            name="genre"
                                            style={(formik1.touched.genre && formik1.errors.genre) ? { borderColor: "red" } : null}
                                            value={formik1.values.genre}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                            <option value="H">Homme</option>
                                            <option value="F">Femme</option>
                                        </select>
                                        {formik1.touched.genre && formik1.errors.genre ? (
                                            <div className="invalid-feedback">{formik1.errors.genre}</div>
                                        ) : null}
                                    </div>

                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statut"
                                            name="statut"
                                            value={formik1.values.statut}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                    <div className="wrap-one">
                                        <label >Username<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="username"
                                            name="username"
                                            placeholder=""
                                            autoComplete="false"
                                            defaultValue={formik1.values.username}
                                            onChange={formik1.handleChange}
                                            style={(formik1.touched.username && formik1.errors.username) ? { borderColor: "red" } : null}
                                        />
                                        {formik1.touched.username && formik1.errors.username ? (
                                            <div className="invalid-feedback">{formik1.errors.username}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <label >Mot de passe<span className='requiredField'>*</span></label>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            style={(formik1.touched.password && formik1.errors.password) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.password}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.password && formik1.errors.password ? (
                                            <div className="invalid-feedback">{formik1.errors.password}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one">
                                        <label >Rôle<span className='requiredField'>*</span></label>
                                        <select
                                            id="role"
                                            name="role"
                                            value={formik1.values.role}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                            {authData?.role != null && authData?.role === "admin" && <option value="admin">Admin</option>}
                                            {/* <option value="agent guichet">Agent guichet</option> */}
                                            <option value="chef agence">Chef agence</option>
                                            <option value="gestionnaire stock">Gestionnaire stock</option>
                                        </select>
                                        {formik1.touched.role && formik1.errors.role ? (
                                            <div className="invalid-feedback">{formik1.errors.role}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            {showUpdateModal && <div className="popcard-add" id="modifier-utilisateur">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" d="close-vehc" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '', '', '', '', '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Modifier utilisateur</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik2.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <label >Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="nomUpdate"
                                            name="nomUpdate"
                                            style={(formik2.touched.nomUpdate && formik2.errors.nomUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.nomUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.nomUpdate && formik2.errors.nomUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.nomUpdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one">
                                        <label >Prénom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="prenomUpdate"
                                            name="prenomUpdate"
                                            style={(formik2.touched.prenomUpdate && formik2.errors.prenomUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.prenomUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.prenomUpdate && formik2.errors.prenomUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.prenomUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Agence<span className='requiredField'>*</span></label>
                                        <select
                                            id="idAgenceUpdate"
                                            name="idAgenceUpdate"
                                            value={formik2.values.idAgenceUpdate}
                                            onChange={formik2.handleChange}
                                            style={(formik2.touched.idAgenceUpdate && formik2.errors.idAgenceUpdate) ? { borderColor: "red" } : null}
                                        >
                                            {authData?.role != null && authData?.role === "admin" && <option value="">Veuillez sélectionner</option>}
                                            {agences.map((agence) => (
                                                <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                            ))}
                                        </select>
                                        {formik2.touched.idAgenceUpdate && formik2.errors.idAgenceUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.idAgenceUpdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label >Genre<span className='requiredField'>*</span></label>
                                        <select
                                            id="genreUpdate"
                                            name="genreUpdate"
                                            value={formik2.values.genreUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                            <option value="H">Homme</option>
                                            <option value="F">Femme</option>
                                        </select>
                                        {formik2.touched.genreUpdate && formik2.errors.genreUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.genreUpdate}</div>
                                        ) : null}
                                    </div>

                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statutUpdate"
                                            name="statutUpdate"
                                            value={formik2.values.statutUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                    <div className="wrap-one">
                                        <label >Username<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="usernameUpdate"
                                            name="usernameUpdate"
                                            placeholder=""
                                            autoComplete="false"
                                            defaultValue={formik2.values.usernameUpdate}
                                            onChange={formik2.handleChange}
                                            style={(formik2.touched.usernameUpdate && formik2.errors.usernameUpdate) ? { borderColor: "red" } : null}
                                        />
                                        {formik2.touched.usernameUpdate && formik2.errors.usernameUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.usernameUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    {(!formik2.values.resetPassword) && <div className="wrap-one">
                                        <Link to={`#`} className='act' style={{ fontSize: '1rem' }} onClick={() => handleResetPassword()}>
                                            Changer mot de passe
                                        </Link>
                                    </div>}
                                    {(formik2.values.resetPassword) && <div className="wrap-one">
                                        <label >Mot de passe<span className='requiredField'>*</span></label>
                                        <input
                                            type="password"
                                            id="newPassword"
                                            name="newPassword"
                                            style={(formik2.touched.newPassword && formik2.errors.newPassword) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.newPassword}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.newPassword && formik2.errors.newPassword ? (
                                            <div className="invalid-feedback">{formik2.errors.newPassword}</div>
                                        ) : null}
                                    </div>}
                                    <div className="wrap-one">
                                        <label >Rôle<span className='requiredField'>*</span></label>
                                        <select
                                            id="roleUpdate"
                                            name="roleUpdate"
                                            value={formik2.values.roleUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                            {authData?.role != null && authData?.role === "admin" && <option value="admin">Admin</option>}
                                            {/* <option value="agent guichet">Agent guichet</option> */}
                                            <option value="chef agence">Chef agence</option>
                                            <option value="gestionnaire stock">Gestionnaire stock</option>
                                        </select>
                                        {formik2.touched.roleUpdate && formik2.errors.roleUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.roleUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer cet utilisateur ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer l'utilisateur"
            />
        </>

    );
}

export default Utilisateurs;
