import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import BarcodeReader from 'react-barcode-reader';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Stock } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const AffectationColis = () => {
    const { authData } = useContext(AuthContext);
    const [colis, setColis] = useState([]);
    const [search, setSearch] = useState('');
    const [lastScannedBarcode, setLastScannedBarcode] = useState('');
    const barcodeReaderRef = useRef(null);
    const scdRef = useRef(null);
    const [isScdFocused, setIsScdFocused] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [trackingManuel, setTrackingManuel] = useState('');

    // Références pour les fichiers audio
    const successSoundRef = useRef(null);
    const errorSoundRef = useRef(null);

    useEffect(() => {
        if (scdRef.current) {
            scdRef.current.focus();
        }

        successSoundRef.current = new Audio('/sounds/success.wav');
        errorSoundRef.current = new Audio('/sounds/error.mp3');

        return () => {
            if (successSoundRef.current) {
                successSoundRef.current.pause();
                successSoundRef.current = null;
            }
            if (errorSoundRef.current) {
                errorSoundRef.current.pause();
                errorSoundRef.current = null;
            }
        };
    }, []);

    const playSound = useCallback((soundRef) => {
        if (soundRef.current) {
            soundRef.current.currentTime = 0;
            soundRef.current.play().catch((error) => {
                console.error('Error playing sound:', error);
            });
        }
    }, []);

    const handleScan = useCallback((data) => {
        if (data) {
            const tracking = data.trim();
            setLastScannedBarcode(tracking);
            if (!colis.some(tr => tr.tracking === tracking)) {
                const newTracking = {
                    tracking,
                    order: colis.length + 1
                };
                setColis([newTracking, ...colis]);
                CustomToast("Le tracking '" + tracking + "' a été ajouté", 'success');
                playSound(successSoundRef);
            } else {
                CustomToast("Le tracking '" + tracking + "' existe déjà", 'info');
                playSound(errorSoundRef);
            }
        }
        if (scdRef.current) scdRef.current.focus();
    }, [colis, playSound]);

    const handleDelete = useCallback((trackingToDelete) => {
        const updatedColis = colis.filter(tracking => tracking.tracking !== trackingToDelete);
        setColis(updatedColis);
        if (scdRef.current) scdRef.current.focus();
    }, [colis]);

    const handleEnregistrer = async () => {
        try {
            const postData = {
                libelleBox: search,
                trackings: colis.map(tracking => tracking.tracking),
                idAgence: authData.idAgence,
                idCompteCrud: authData.idCompte
            };
            const response = await axiosInstance.post(`${Stock}/Affectation`, postData);
            CustomToast("Succès", 'success');
            setSearch('');
            setColis([]);
        } catch (error) {
            // Gestion des erreurs
        }
    };

    //Ajouter tracking manuelement
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        setTrackingManuel('');
        if(!val){
            if (scdRef.current) scdRef.current.focus();
        }
    };

    const handleAddManualTracking = (e) => {
        e.preventDefault();
        if (trackingManuel.trim() === '') {
            CustomToast("Le champ 'Tracking' ne peut pas être vide", 'error');
            if (scdRef.current) scdRef.current.focus();
            playSound(errorSoundRef);
            return;
        }
        if (!colis.some(tr => tr.tracking === trackingManuel)) {
            const newTracking = {
                tracking: trackingManuel,
                order: colis.length + 1
            };
            setColis([newTracking, ...colis]);
            CustomToast("Le tracking '" + trackingManuel + "' a été ajouté", 'success');
            playSound(successSoundRef);
            setTrackingManuel('');
            setShowAddModal(false);
        } else {
            CustomToast("Le tracking '" + trackingManuel + "' existe déjà", 'info');
            playSound(errorSoundRef);
        }

        if (scdRef.current) scdRef.current.focus();
    };

    return (
        <>
            <div className="container affectation-colis-section">
                <div className="grid-low-row-gap grid--5-cols grid--1-row grid--start-v mt-2">
                    <div className="detail-form fst">
                        <div className="col">
                            <label>Boite</label>
                            <input
                                type="search"
                                placeholder="Qr code boite"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                autoFocus={true}
                            />
                        </div>
                    </div>
                    {search && (
                        <>
                            <button className="submit-btn submit-btn-green gst" type='button' onClick={() => showHideAddModal(true)}>
                                <span className="material-symbols-outlined search-btn">add</span>
                            </button>
                            <div
                                className={`detail-form scd ${isScdFocused ? 'focused' : ''}`}
                                tabIndex={0}
                                ref={scdRef}
                                onFocus={() => setIsScdFocused(true)}
                                onBlur={() => setIsScdFocused(false)}
                            >
                                <BarcodeReader
                                    onError={(err) => {
                                        console.error(err);
                                        CustomToast("Erreur de lecture du code-barres", 'error');
                                    }}
                                    onScan={handleScan}
                                />
                                <div className="col table-wrap">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Tracking</th>
                                                <th>Supprimer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {colis.map((tracking, index) => (
                                                <tr key={index}>
                                                    <td>{tracking.tracking}</td>
                                                    <td onClick={() => handleDelete(tracking.tracking)} style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                        <span className="material-symbols-outlined danger">delete</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {colis.length > 0 && (
                                    <div className="col">
                                        <input
                                            type="button"
                                            value="Enregistrer"
                                            className="submit-btn-small"
                                            onClick={handleEnregistrer}
                                        />
                                    </div>
                                )}
                            </div>
                        </>)}
                </div>
            </div>

            {showAddModal && <div className="popcard-add" id="ajouter-colis">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" d="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Ajouter colis</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={handleAddManualTracking}>
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Tracking<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="trackingManuel"
                                            name="trackingManuel"
                                            placeholder="yal-xxxxxx"
                                            maxLength={10}
                                            minLength={10}
                                            value={trackingManuel} 
                                            onChange={(e) => setTrackingManuel(e.target.value)} 
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default AffectationColis;
