import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Agence, Wilaya, Commune } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const AgenceParams = () => {
    const { authData } = useContext(AuthContext);
    const [wilayas, setWilayas] = useState([]);
    const [communes, setCommunes] = useState([]);
    const formik1 = useFormik({
        initialValues: {
            libelle: '',
            adresse: '',
            codeWilaya: '',
            codeCommune: '',
            manager: '',
            libelleAgenceTicket: ''
        },
        validationSchema: Yup.object({
            libelle: Yup.string()
                .required('Champ obligatoire'),
            libelleAgenceTicket: Yup.string()
                .required('Champ obligatoire'),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idAgence: authData.idAgence,
                    libelle: values.libelle,
                    adresse: values.adresse,
                    codeWilaya: values.codeWilaya,
                    codeCommune: values.codeCommune,
                    manager: values.manager,
                    libelleAgenceTicket: values.libelleAgenceTicket,
                    idCompteCrud: authData.idCompte
                };

                const formData = new FormData();
                formData.append('idAgence', authData.idAgence);
                formData.append('libelle', values.libelle);
                formData.append('adresse', values.adresse);
                formData.append('codeWilaya', values.codeWilaya);
                formData.append('codeCommune', values.codeCommune);
                formData.append('manager', values.manager);
                formData.append('libelleAgenceTicket', values.libelleAgenceTicket);
                formData.append('idCompteCrud', authData.idCompte);

                //console.log(postData)
                const response = await axiosInstance.put(Agence, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("L'agence a été modifiée avec succès", 'success');
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Agence existe déjà.") {
                    CustomToast("Agence existe déjà.", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Agence n'existe pas.") {
                    CustomToast("Agence n'existe pas.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //load data
    const getAgence = async () => {
        try {
            if (authData?.idAgence == null || authData?.idAgence === "") {
                return;
            }

            const response = await axiosInstance.get(`${Agence}/${authData.idAgence}`);
            const resp = await response.data;
            formik1.setValues((prevValues) => ({
                ...prevValues,
                libelle: resp.libelle || '',
                adresse: resp.adresse || '',
                codeWilaya: resp.codeWilaya || '',
                codeCommune: resp.codeCommune || '',
                manager: resp.manager || '',
                libelleAgenceTicket: resp.libelleAgenceTicket || ''
            }));
        }
        catch (err) {
            console.log(err);
        }
    }

    const getWilayas = async () => {
        try {
            const response = await axiosInstance.get(`${Wilaya}`);
            const resp = await response.data;
            setWilayas(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getCommunes = async (codeWilaya) => {
        try {
            const response = await axiosInstance.get(`${Commune}/GetCommuneByCodeWilaya/${codeWilaya}`);
            const resp = await response.data;
            setCommunes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        getAgence();

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getWilayas();

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load communes
        if (formik1.values.codeWilaya) {
            getCommunes(formik1.values.codeWilaya);
        }
        else {
            setCommunes([]);
            formik1.values.codeCommune = "";
        }
    }, [formik1.values.codeWilaya])

    return (
        <div className="container paramt-application">
            <h1></h1>
            <form onSubmit={formik1.handleSubmit} >
                <div className="detail-form detail-form-agence">
                    <h3 className="heading-primary">Agence</h3>
                    <div className="col">
                        <div className="grp">
                            <div className="gp">
                                <label>Nom</label>
                                <input type="text"
                                    id="libelle"
                                    name="libelle"
                                    style={(formik1.touched.libelle && formik1.errors.libelle) ? { borderColor: "red" } : null}
                                    placeholder=""
                                    defaultValue={formik1.values.libelle}
                                    onChange={formik1.handleChange}
                                />
                                {formik1.touched.libelle && formik1.errors.libelle ? (
                                    <div className="invalid-feedback">{formik1.errors.libelle}</div>
                                ) : null}
                            </div>
                            <div className="gp">
                                <label>Nom de l’agence sur ticket</label>
                                <input type="text"
                                    id="libelleAgenceTicket"
                                    name="libelleAgenceTicket"
                                    style={(formik1.touched.libelleAgenceTicket && formik1.errors.libelleAgenceTicket) ? { borderColor: "red" } : null}
                                    placeholder=""
                                    defaultValue={formik1.values.libelleAgenceTicket}
                                    onChange={formik1.handleChange}
                                />
                                {formik1.touched.libelleAgenceTicket && formik1.errors.libelleAgenceTicket ? (
                                    <div className="invalid-feedback">{formik1.errors.libelleAgenceTicket}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="grp">
                            <div className="gp">
                                <label>Adresse</label>
                                <input type="text"
                                    id="adresse"
                                    name="adresse"
                                    placeholder=""
                                    defaultValue={formik1.values.adresse}
                                    onChange={formik1.handleChange}
                                />
                            </div>
                            <div className="gp">
                                <label>Wilaya</label>
                                <select
                                    id="codeWilaya"
                                    name="codeWilaya"
                                    value={formik1.values.codeWilaya} onChange={formik1.handleChange}>
                                    <option value="">Veuillez sélectionner</option>
                                    {wilayas.map((wilaya) => (
                                        <option key={wilaya.idWilaya} value={wilaya.code}>{wilaya.nomLatin}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="grp">
                            <div className="gp">
                                <label>Commune</label>
                                <select
                                    id="codeCommune"
                                    name="codeCommune"
                                    value={formik1.values.codeCommune} onChange={formik1.handleChange}>
                                    <option value="">Veuillez sélectionner</option>
                                    {communes.map((commune) => (
                                        <option key={commune.idCommune} value={commune.code}>{commune.nomLatin}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="gp">
                                <label>Manager</label>
                                <input type="text"
                                    id="manager"
                                    name="manager"
                                    placeholder=""
                                    defaultValue={formik1.values.manager}
                                    onChange={formik1.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="submit-container">
                    <input type="submit" value="Enregistrer" className="submit-btn submit-btn-green" />
                </div>
            </form>
        </div>
    );
}

export default AgenceParams;
