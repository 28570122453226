import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/layout/Layout.js';
import Login from './pages/login/Login.js';
import Empty from "./pages/empty/Empty";
import Utilisateurs from "./pages/utilisateurs/Utilisateurs.js";
import Guichets from "./pages/guichets/Guichets.js";
import AppGuichet from "./pages/guichets/AppGuichet.js";
import Materiels from "./pages/materiels/Materiels.js";
import Agences from "./pages/agences/Agences.js";
import AgenceParams from "./pages/config/AgenceParams.js";
import FileAttente from "./pages/config/FileAttente.js";
import StockParams from "./pages/config/StockParams.js";
import Services from "./pages/services/Services.js";
import AffectationColis from "./pages/Colis/AffectationColis.js";
import ListeColis from "./pages/Colis/ListeColis.js";
import Demandes from "./pages/Colis/Demandes.js";
import Ecran from "./pages/ecran/Ecran.js";

import Unauthorized from './pages/Errors/Unauthorized';
import NotFound from './pages/Errors/NotFound';

import ProtectedRoute from './services/Securite/ProtectedRoute';
import ProtectedRouteSuperAdmin from './services/Securite/ProtectedRouteSuperAdmin';

function App() {
  return (
    <>
     <BrowserRouter> 
     {/* basename="/Qapp" : Pour installer l'application en local */}
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />

            <Route element={<Layout />} >                                                    
              <Route path="/" element={<ProtectedRoute allowedRoles={['admin','chef agence']} />}>
                <Route path="/empty" element={<Empty />} />
                <Route path="/utilisateurs" element={<Utilisateurs />} />
                <Route path="/guichets" element={<Guichets />} />
                <Route path="/materiels" element={<Materiels />} />
                <Route path="/agenceparams" element={<AgenceParams />} />
                <Route path="/fileattente" element={<FileAttente />} />
              </Route>
            </Route>

            <Route element={<Layout />} >                                                    
              <Route path="/" element={<ProtectedRoute allowedRoles={['admin']} />}>
                <Route path="/agences" element={<Agences />} />
                <Route path="/services" element={<Services />} />
              </Route>
            </Route>

            <Route element={<Layout />} >                                                    
              <Route path="/" element={<ProtectedRoute allowedRoles={['agent guichet','admin']} />}>
                <Route path="/appguichet" element={<AppGuichet />} />
              </Route>
            </Route>

            <Route element={<Layout />} >                                                    
              <Route path="/" element={<ProtectedRoute allowedRoles={['chef agence','admin','gestionnaire stock']} />}>
                <Route path="/stockparams" element={<StockParams />} />
                <Route path="/affectationcolis" element={<AffectationColis />} />
                <Route path="/listecolis" element={<ListeColis />} />
              </Route>
            </Route>

            <Route element={<Layout />} >                                                    
              <Route path="/" element={<ProtectedRoute allowedRoles={['chef agence','admin','gestionnaire stock']} />}>
                <Route path="/demandes" element={<Demandes />} />
              </Route>
            </Route>

            <Route element={<Layout />} >                                                    
              <Route path="/" element={<ProtectedRoute allowedRoles={['chef agence','admin','materiel']} />}>
                <Route path="/ecran" element={<Ecran />} />
              </Route>
            </Route>           

            <Route path="unauthorized" element={<Unauthorized />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
      <ToastContainer style={{ fontSize: '1rem' }} />
    </>
  );
}

export default App;
