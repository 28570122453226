import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import Pagination from '../../components/pagination/Pagination';
import { axiosInstance, FileAttenteParams, Agence, Service } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';

const FileAttente = () => {
    const { authData } = useContext(AuthContext);
    const [fa, setFa] = useState([]);
    const [agences, setAgences] = useState([]);
    const [services, setServices] = useState([]);
    const [filtredFa, setFiltredFa] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showInitModal, setShowInitModal] = useState(false);
    const [showDesactiverModal, setShowDesactiverModal] = useState(false);
    const [idFileAttenteDelete, setIdFileAttenteDelete] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredFa].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredFa(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = fa.slice(startIndex, endIndex);

        setFiltredFa(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Ajouter une file d'attente
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
        getAgences();
        getServices();
    };

    const formik1 = useFormik({
        initialValues: {
            idAgence: '',
            libelle: '',
            statut: 'actif',
            maxNumTicket: '',
            idServices: [],
            prefix: ''
        },
        validationSchema: Yup.object({
            idAgence: Yup.string()
                .required('Champ obligatoire'),
            libelle: Yup.string()
                .required('Champ obligatoire'),
            statut: Yup.string()
                .required('Champ obligatoire'),
            prefix: Yup.string()
                .required('Champ obligatoire'),
            maxNumTicket: Yup.number()
                .required('Champ obligatoire')
                .min(1, "Minimum 1")
                .max(999, "Maximum 999"),
            idServices: Yup.array().min(1, 'Veuillez sélectionner au moins un service.'),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idAgence: values.idAgence,
                    libelle: values.libelle,
                    statut: values.statut,
                    maxNumTicket: values.maxNumTicket,
                    prefix: values.prefix,
                    idServices: values.idServices,
                    idCompteCrud: authData.idCompte
                };
                //console.log(values)
                const response = await axiosInstance.post(FileAttenteParams, postData);
                CustomToast("La file d'attente a étée créée avec succès", 'success');
                getFA(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "File d'attente existe déjà.") {
                    CustomToast("File d'attente existe déjà.", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Prefixe existe déjà.") {
                    CustomToast("Prefixe existe déjà.", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Une file d'attente active pour ce service existe déjà pour cette agence.") {
                    CustomToast("Une file d'attente active pour ce service existe déjà.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier une file d'attente
    const showHideUpdateModal = (val, idFileAttente, idAgence, libelle, statut, maxNumTicket, idServices, prefix) => {
        setShowUpdateModal(val);
        formik2.resetForm();
        if (val) {
            getAgences();
            getServices();
            formik2.setValues({
                idFileAttente: idFileAttente,
                idAgenceUpdate: idAgence,
                libelleUpdate: libelle,
                statutUpdate: statut,
                maxNumTicketUpdate: maxNumTicket,
                prefixUpdate: prefix,
                idServicesUpdate: idServices,
            });
        }
    };

    const formik2 = useFormik({
        initialValues: {
            idFileAttente: '',
            idAgenceUpdate: '',
            libelleUpdate: '',
            statutUpdate: 'actif',
            maxNumTicketUpdate: '',
            idServicesUpdate: [],
            prefixUpdate: ''
        },
        validationSchema: Yup.object({
            idAgenceUpdate: Yup.string()
                .required('Champ obligatoire'),
            libelleUpdate: Yup.string()
                .required('Champ obligatoire'),
            statutUpdate: Yup.string()
                .required('Champ obligatoire'),
            prefixUpdate: Yup.string()
                .required('Champ obligatoire'),
            maxNumTicketUpdate: Yup.number()
                .required('Champ obligatoire')
                .min(1, "Minimum 1")
                .max(999, "Maximum 999"),
            idServicesUpdate: Yup.array().min(1, 'Veuillez sélectionner au moins un service.'),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idFileAttente: values.idFileAttente,
                    idAgence: values.idAgenceUpdate,
                    libelle: values.libelleUpdate,
                    statut: values.statutUpdate,
                    maxNumTicket: values.maxNumTicketUpdate,
                    prefix: values.prefixUpdate,
                    idServices: values.idServicesUpdate,
                    idCompteCrud: authData.idCompte
                };
                //console.log(postData);
                const response = await axiosInstance.put(FileAttenteParams, postData);
                CustomToast("La file d'attente a été modifiée avec succès", 'success');
                getFA(pageNumber, pageSize);
                setShowUpdateModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "File d'attente existe déjà.") {
                    CustomToast("File d'attente existe déjà.", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Prefixe existe déjà.") {
                    CustomToast("Prefixe existe déjà.", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Une file d'attente active pour ce service existe déjà pour cette agence.") {
                    CustomToast("Une file d'attente active pour ce service existe déjà.", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "File d'attente n'existe pas.") {
                    CustomToast("File d'attente n'existe pas.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Initialiser une file d'attente
    const showHideInitModal = (val, idCfgFileAttente, statut, prefix, capaciteMax) => {
        setShowInitModal(val);
        formik3.resetForm();
        if (val) {
            formik3.setValues({
                idCfgFileAttente: idCfgFileAttente,
                prefixInit: prefix,
                statutInit: statut,
                forcerAnnulationTickets: true,
                capaciteMaxInit: capaciteMax,
            });
        }
    };

    const formik3 = useFormik({
        initialValues: {
            idCfgFileAttente: '',
            prefixInit: '',
            statutInit: '',
            forcerAnnulationTickets: true,
            capaciteMaxInit: ''
        },
        onSubmit: async (values) => {
            try {
                const postData = {
                    idCfgFileAttente: values.idCfgFileAttente,
                    prefix: values.prefixInit,
                    statut: values.statutInit,
                    forcerAnnulationTickets: values.forcerAnnulationTickets,
                    capaciteMax: values.capaciteMaxInit,
                    idCompteCrud: authData.idCompte
                };
                //console.log(postData);
                const response = await axiosInstance.put(`${FileAttenteParams}/ReinitialiserFA`, postData);

                CustomToast("La file d'attente a été réinitialisée avec succès", 'success');
                getFA(pageNumber, pageSize);
                setShowInitModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Desactiver une file d'attente
    const showHideDesactiverModal = (val, idCfgFileAttente) => {
        setShowDesactiverModal(val);
        formik4.resetForm();
        if (val) {
            formik4.setValues({
                idCfgFileAttente: idCfgFileAttente,
            });
        }
    };

    const formik4 = useFormik({
        initialValues: {
            idCfgFileAttente: ''
        },
        onSubmit: async (values) => {
            try {
                const postData = {
                    idCfgFileAttente: values.idCfgFileAttente,
                    idCompteCrud: authData.idCompte
                };
                //console.log(postData);
                const response = await axiosInstance.put(`${FileAttenteParams}/DesactiverFA`, postData);

                CustomToast("La file d'attente a été désactivée avec succès", 'success');
                getFA(pageNumber, pageSize);
                setShowDesactiverModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer une file d'attente
    const handleConfirmDelete = (idFileAttente) => {
        setIdFileAttenteDelete(idFileAttente);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${FileAttenteParams}/${idFileAttenteDelete}`);
            setIdFileAttenteDelete('');
            CustomToast("La file d'attente a été supprimée avec succès", 'success');
            getFA(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400) {
                CustomToast("Suppression impossible, file d'attente déjà utilisée", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Service introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data

    const getFA = async (page, newPerPage) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            // setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }
            // if (authData?.role != null && authData?.role !== "admin") {
            //     queryParams.append("idAgence", authData.idAgence);
            // }
            if (authData?.role != null  && authData?.role !== "") {
                if (authData?.role !== "admin") {
                    queryParams.append("idAgence", authData?.idAgence);
                }                
            }else{
                return;
            }
            const response = await axiosInstance.get(`${FileAttenteParams}/GetFilesAttenteListe?${queryParams}`);
            const resp = await response.data;
            setFa(resp.data);
            setFiltredFa(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            // setLoading(false);
        }finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const getAgences = async () => {
        try {
            if (authData?.role != null && authData?.role === "admin") {
                const response = await axiosInstance.get(`${Agence}`);
                const resp = await response.data;
                setAgences(resp);
            } else {
                const queryParams = new URLSearchParams({
                    idAgence: authData.idAgence
                });
                const response = await axiosInstance.get(`${Agence}?${queryParams}`);
                const resp = await response.data;
                formik1.setValues((prevValues) => ({
                    ...prevValues,
                    idAgence: authData.idAgence
                }));
                setAgences(resp);
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    const getServices = async () => {
        try {
            const response = await axiosInstance.get(`${Service}`);
            const resp = await response.data;
            setServices(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        getFA(pageNumber, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize, debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getFA(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);

    return (
        <>
            <div className="container Paramétrage-section">
                <div className="affectation-sec wrap-all">
                    <Link href="#" className="utilisateur-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter une file d'attente
                        </div>
                    </Link>
                    <div className="group-elements">
                        <div className="voir-elements">
                            {/* <p>voir</p> */}
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            {/* <p>Rechercher</p> */}
                            <input
                                type="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredFa?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    {authData?.role != null && authData?.role === "admin" && <th>Agence</th>}
                                    <th>Nom</th>
                                    <th>Capacité max</th>
                                    <th>Prefix</th>
                                    <th>Statut</th>
                                    <th>Services</th>
                                    <th>Réinitialiser</th>
                                    <th>Désactiver</th>
                                    <th>Modifier</th>
                                    <th>Supprimer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredFa?.map((row) => (
                                    <tr key={row.idFileAttente}>
                                        {authData?.role != null && authData?.role === "admin" && <td>{row.libelleAgence}</td>}
                                        <td>{row.libelleFileAttente}</td>
                                        <td>{row.maxNumTicket}</td>
                                        <td>{row.prefix}</td>
                                        <td>{
                                            row.statut === "actif" ? <>Actif</> :
                                                row.statut === "inactif" ? <>Inactif</> :
                                                    null
                                        }</td>
                                        <td>{row.libelleService ? row.libelleService.join(', ') : ''}</td>
                                        <td className="txt-center" onClick={() => showHideInitModal(true, row.idFileAttente,row.statut, row.prefix, row.maxNumTicket)}>
                                            <span className="material-symbols-outlined">restart_alt
                                            </span></td>
                                        <td className="txt-center" onClick={() => showHideDesactiverModal(true, row.idFileAttente)}>
                                            <span className="material-symbols-outlined">lock
                                            </span></td>
                                        <td className="txt-center" onClick={() => showHideUpdateModal(true, row.idFileAttente, row.idAgence, row.libelleFileAttente, row.statut, row.maxNumTicket, row.idServices, row.prefix)}>
                                            <span className="material-symbols-outlined">edit
                                            </span></td>
                                        <td className="txt-center" onClick={() => handleConfirmDelete(row.idFileAttente)}>
                                            <span className="material-symbols-outlined">delete</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {/* <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Link
                                key={i + 1}
                                to="#"
                                className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Link>
                        ))}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                            disabled={((pageNumber + 1) > totalPages) ? true : false}
                        >&raquo;</Link>
                    </div> */}
                    {filtredFa?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>

            </div>
            {showAddModal && <div className="popcard-add" id="ajouter-Paramétrage">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-add" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Ajouter file d'attente</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Agence<span className='requiredField'>*</span></label>
                                        <select
                                            id="idAgence"
                                            name="idAgence"
                                            value={formik1.values.idAgence}
                                            onChange={formik1.handleChange}
                                            style={(formik1.touched.idAgence && formik1.errors.idAgence) ? { borderColor: "red" } : null}
                                        >
                                            {authData?.role != null && authData?.role === "admin" && <option value="">Veuillez sélectionner</option>}
                                            {agences.map((agence) => (
                                                <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idAgence && formik1.errors.idAgence ? (
                                            <div className="invalid-feedback">{formik1.errors.idAgence}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelle"
                                            name="libelle"
                                            style={(formik1.touched.libelle && formik1.errors.libelle) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.libelle}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.libelle && formik1.errors.libelle ? (
                                            <div className="invalid-feedback">{formik1.errors.libelle}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Capacité max<span className='requiredField'>*</span></label>
                                        <input
                                            type="number"
                                            id="maxNumTicket"
                                            name="maxNumTicket"
                                            style={(formik1.touched.maxNumTicket && formik1.errors.maxNumTicket) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.maxNumTicket}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.maxNumTicket && formik1.errors.maxNumTicket ? (
                                            <div className="invalid-feedback">{formik1.errors.maxNumTicket}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statut"
                                            name="statut"
                                            value={formik1.values.statut}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <label>Prefix<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="prefix"
                                            name="prefix"
                                            style={(formik1.touched.prefix && formik1.errors.prefix) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.prefix}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.prefix && formik1.errors.prefix ? (
                                            <div className="invalid-feedback">{formik1.errors.prefix}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        {services.map(service => (
                                            <div key={service.idService} className="wrap-row">
                                                <input
                                                    type="checkbox"
                                                    name="idServices"
                                                    value={service.idService}
                                                    checked={formik1.values.idServices.includes(String(service.idService))}
                                                    onChange={(e) => {
                                                        const { value, checked } = e.target;
                                                        if (checked) {
                                                            formik1.setFieldValue('idServices', [...formik1.values.idServices, value]);
                                                        } else {
                                                            formik1.setFieldValue('idServices', formik1.values.idServices.filter((id) => id !== value));
                                                        }
                                                    }} />
                                                <label>{service.libelle}</label>
                                            </div>
                                        ))}
                                    </div>
                                    {formik1.touched.idServices && formik1.errors.idServices ? (
                                        <div className="invalid-feedback">{formik1.errors.idServices}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            {showUpdateModal && <div className="popcard-add" id="modifier-Paramétrage">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-update" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '', [])}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Modifier file d'attente</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik2.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Agence<span className='requiredField'>*</span></label>
                                        <select
                                            id="idAgenceUpdate"
                                            name="idAgenceUpdate"
                                            value={formik2.values.idAgenceUpdate}
                                            onChange={formik2.handleChange}
                                            style={(formik2.touched.idAgenceUpdate && formik2.errors.idAgenceUpdate) ? { borderColor: "red" } : null}
                                        >
                                            {authData?.role != null && authData?.role === "admin" && <option value="">Veuillez sélectionner</option>}
                                            {agences.map((agence) => (
                                                <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                            ))}
                                        </select>
                                        {formik2.touched.idAgenceUpdate && formik2.errors.idAgenceUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.idAgenceUpdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelleUpdate"
                                            name="libelleUpdate"
                                            style={(formik2.touched.libelleUpdate && formik2.errors.libelleUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.libelleUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.libelleUpdate && formik2.errors.libelleUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.libelleUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Capacité max<span className='requiredField'>*</span></label>
                                        <input
                                            type="number"
                                            id="maxNumTicketUpdate"
                                            name="maxNumTicketUpdate"
                                            style={(formik2.touched.maxNumTicketUpdate && formik2.errors.maxNumTicketUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.maxNumTicketUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.maxNumTicketUpdate && formik2.errors.maxNumTicketUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.maxNumTicketUpdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statutUpdate"
                                            name="statutUpdate"
                                            value={formik2.values.statutUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <label>Prefix<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="prefixUpdate"
                                            name="prefixUpdate"
                                            style={(formik2.touched.prefixUpdate && formik2.errors.prefixUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.prefixUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.prefixUpdate && formik2.errors.prefixUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.prefixUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        {services.map(service => (
                                            <div key={service.idService} className="wrap-row">
                                                <input
                                                    type="checkbox"
                                                    name="idServicesUpdate"
                                                    value={service.idService}
                                                    // checked={formik2.values.idServicesUpdate.includes(String(service.idService))}
                                                    checked={formik2.values.idServicesUpdate.includes(service.idService)}
                                                    // onChange={(e) => {
                                                    //     const { value, checked } = e.target;
                                                    //     if (checked) {
                                                    //         formik2.setFieldValue('idServicesUpdate', [...formik2.values.idServicesUpdate, value]);
                                                    //     } else {
                                                    //         formik2.setFieldValue('idServicesUpdate', formik2.values.idServicesUpdate.filter((id) => id !== value));
                                                    //     }
                                                    // }} 
                                                    onChange={(e) => {
                                                        const { value, checked } = e.target;
                                                        const intValue = parseInt(value, 10); // Convertit la valeur en entier
                                                        if (checked) {
                                                            formik2.setFieldValue('idServicesUpdate', [...formik2.values.idServicesUpdate, intValue]);
                                                        } else {
                                                            formik2.setFieldValue('idServicesUpdate', formik2.values.idServicesUpdate.filter((id) => id !== intValue));
                                                        }
                                                    }}
                                                />
                                                <label>{service.libelle}</label>
                                            </div>
                                        ))}
                                    </div>
                                    {formik2.touched.idServicesUpdate && formik2.errors.idServicesUpdate ? (
                                        <div className="invalid-feedback">{formik2.errors.idServicesUpdate}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            {showInitModal && <div className="popcard-add" id="initialiser-Paramétrage">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-init" to="#" onClick={() => showHideInitModal(false, '', '', '', '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Réinitialiser la file d'attente</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik3.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <div class="wrap-row">
                                            <input
                                                type="checkbox"
                                                id="forcerAnnulationTickets"
                                                name="forcerAnnulationTickets"
                                                checked={formik3.values.forcerAnnulationTickets}
                                                onChange={formik3.handleChange}
                                            />
                                            <label htmlFor="forcerAnnulationTickets">Forcer l'annulation de tous les tickets en attente</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <strong>Êtes-vous sûr de vouloir réinitialiser la file d'attente ? Cette action est irréversible</strong>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-red" onClick={() => showHideInitModal(false, '', '', '', '')}>Annuler</button>
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            {showDesactiverModal && <div className="popcard-add" id="initialiser-Paramétrage">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-init" to="#" onClick={() => showHideDesactiverModal(false, '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Désactiver la file d'attente</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik4.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <strong>Êtes-vous sûr de vouloir désactiver la file d'attente ? Cette action est irréversible 
                                        et tous les tickets en attente seront annulés.</strong>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-red" onClick={() => showHideDesactiverModal(false, '')}>Annuler</button>
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer cette file d'attente ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer la file d'attente"
            />
        </>

    );
}

export default FileAttente;
