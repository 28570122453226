import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import { axiosInstance, Visiteur, AppHub } from '../../services/api';
import { isConnectionConnecting, createConnection, joinGroup } from '../../services/SignalRService';
import { AuthContext } from '../../contexts/AuthContext';

const AppGuichet = () => {
    const { authData } = useContext(AuthContext);
    //const [connexionApp, setConnexionApp] = useState(createConnection(AppHub));
    // // Rejoindre le groupe approprié
    // joinGroup(connexionDashboard, authData.idAgenec);
    // const [connexionDashboard, setConnexionDashboard] = useState(null); // Initialisez la connexion à null

    // useEffect(() => {
    //     // Créez une nouvelle connexion uniquement si elle n'existe pas déjà
    //     if (!connexionDashboard) {
    //         const newConnection = createConnection(AppHub);
    //         setConnexionDashboard(newConnection);
    //         joinGroup(newConnection, authData.idAgenec); // Rejoignez le groupe approprié
    //     }

    //     return () => {
    //         // Arrêtez la connexion lorsque le composant est démonté
    //         if (isConnectionConnecting(connexionDashboard)) {
    //             connexionDashboard.stop();
    //         }
    //     };
    // }, []);//authData
    //Retour
    const [showAddModalRetour, setShowAddModalRetour] = useState(false);
    const [retour, setRetour] = useState(false);

    const [showAddModalAppelerProchainVisiteur, setShowAddModalAppelerProchainVisiteur] = useState(false);
    const [showAddModalAppelerVisiteur, setShowAddModalAppelerVisiteur] = useState(false);
    const [showAddModalSuiviColis, setShowAddModalSuiviColis] = useState(false);
    const [showAddModalRemiseDirecte, setShowAddModalRemiseDirecte] = useState(false);
    const [showAddModalEnleverClient, setShowAddModalEnleverClient] = useState(false);
    // const [numTicketActuel, setNumTicketActuel] = useState('000');
    //const [idTicketActuel, setIdTicketActuel] = useState('');
    const [numTicketActuel, setNumTicketActuel] = useState(localStorage.getItem('numTicketActuel') || '000');
    const [idTicketActuel, setIdTicketActuel] = useState(localStorage.getItem('idTicketActuel') || '');
    //const [idServiceActuel, setIdServiceActuel] = useState('');

    const [numTicketPre, setNumTicketPre] = useState('000');
    const [idTicketPre, setIdTicketPre] = useState('');

    const [visiteursAppele, setVisiteursAppele] = useState([]);
    const [visiteursAppeleErreur, setVisiteursAppeleErreur] = useState('');

    const [colis, setColis] = useState([]);


    const showHideModalAppelerVisiteur = (val) => {
        setShowAddModalAppelerVisiteur(val);
        if (val) {
            getVisiteursAppele();
        }
    };

    const showHideModalSuiviColis = (val) => {
        setShowAddModalSuiviColis(val);
    };

    const showHideModalRemiseDirecte = (val) => {
        setShowAddModalRemiseDirecte(val);
    };

    const showHideModalEnleverClient = (val) => {
        setShowAddModalEnleverClient(val);
        if (val) {
            getVisiteursElever();
        }
    };


    //Retour
    const retourVisiteur = async () => {
        try {
            const postData = {
                idTicket: idTicketActuel,
                idCompteGuichet: authData.idCompte
            };
            const response = await axiosInstance.post(`${Visiteur}/RetourFileAttente`, postData);
            setIdTicketActuel(response.data.idTicketActuel);
            setNumTicketActuel(response.data.numTicketActuel);
            //setIdServiceActuel(response.data.idService);
            setIdTicketPre(response.data.idTicketPre);
            setNumTicketPre(response.data.numTicketPre);
            setShowAddModalRetour(false);
            CustomToast("Le visiteur est revenu à la file d'attente", 'success');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Guichet introuvable.") {
                CustomToast("Guichet introuvable.", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Ticket introuvable.") {
                CustomToast("Ticket introuvable.", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Aucun ticket.") {
                setIdTicketPre(idTicketActuel);
                setNumTicketPre(numTicketActuel);
                CustomToast("Aucun ticket.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelRetour = () => {
        setShowAddModalRetour(false);
    };

    //Appeler prochain visiteur
    const prochainVisiteur = async () => {
        try {
            const postData = {
                idCompte: authData.idCompte
            };
        const response = await axiosInstance.post(`${Visiteur}/ProchainTicket`, postData);
        setIdTicketActuel(response.data.idTicket);
        setNumTicketActuel(response.data.numTicket);
        setShowAddModalAppelerProchainVisiteur(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Guichet introuvable.") {
                CustomToast("Guichet introuvable.", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "File d'attente introuvable.") {
                CustomToast("File d'attente vide.", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Aucun ticket.") {
                CustomToast("File d'attente vide.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            setShowAddModalAppelerProchainVisiteur(false);
            console.log(error);
        }
    }

    const handleCancelProchainVisiteur = () => {
        setShowAddModalAppelerProchainVisiteur(false);
    };

    //Appeler par numéro

    const getVisiteursAppele = async () => {
        try {
            const response = await axiosInstance.get(`${Visiteur}/GetTicketDisponible/${authData?.idCompte}`);
            const resp = await response.data;
            setVisiteursAppele(resp);
            setVisiteursAppeleErreur('');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Guichet introuvable.") {
                CustomToast("Guichet introuvable.", 'error');
                setVisiteursAppeleErreur("Guichet introuvable.");
            } else if (error.response?.status === 400 && error.response?.data === "File d'attente introuvable.") {
                CustomToast("File d'attente vide.", 'error');
                setVisiteursAppeleErreur("File d'attente vide.");
            } else if (error.response?.status === 404 && error.response?.data === "Vide") {
                CustomToast("File d'attente vide.", 'error');
                setVisiteursAppeleErreur("File d'attente vide.");
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            setShowAddModalAppelerVisiteur(false);
            console.log(error);
        }
    }

    const prochainVisiteurTab = async (idTicket) => {
        try {
            const postData = {
                idTicket: idTicket,
                idCompteGuichet: authData.idCompte
            };
            const response = await axiosInstance.post(`${Visiteur}/ProchainTicketByTicket`, postData);
            setIdTicketActuel(response.data.idTicket);
            setNumTicketActuel(response.data.numTicket);
            showHideModalAppelerVisiteur(false);
            //getVisiteursAppele();
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Aucun ticket.") {
                CustomToast("File d'attente vide.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
            showHideModalAppelerVisiteur(false);
        }
    }

    //Enlever les visiteurs de la file d'attente
    const getVisiteursElever = async () => {
        try {
            const response = await axiosInstance.get(`${Visiteur}/GetTicketDisponible/${authData?.idCompte}`);
            const resp = await response.data;
            setVisiteursAppele(resp);
            setVisiteursAppeleErreur('');
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Guichet introuvable.") {
                CustomToast("File d'attente introuvable.", 'error');
                setVisiteursAppeleErreur("File d'attente introuvable.");
            } else if (error.response?.status === 400 && error.response?.data === "File d'attente introuvable.") {
                CustomToast("File d'attente introuvable.", 'error');
                setVisiteursAppeleErreur("File d'attente introuvable.");
            } else if (error.response?.status === 404 && error.response?.data === "Vide") {
                CustomToast("File d'attente vide.", 'error');
                setVisiteursAppeleErreur("File d'attente vide.");
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            showHideModalEnleverClient(false);
            console.log(error);
        }
    }

    const enleverTicket = async (idTicket) => {
        try {
            const postData = {
                idTicket: idTicket,
                idCompteGuichet: authData.idCompte
            };
            const response = await axiosInstance.post(`${Visiteur}/EnleverTicketByTicket`, postData);
            getVisiteursElever();
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Aucun ticket.") {
                CustomToast("File d'attente vide.", 'error');
                showHideModalEnleverClient(false);
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    //Remise directe
    const formik1 = useFormik({
        initialValues: {
            trackingRemiseDirecte: '',
            service: ''
        },
        validationSchema: Yup.object({
            trackingRemiseDirecte: Yup.string()
                .required('Champ obligatoire'),
                service: Yup.string()
                .required('Champ obligatoire'),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    tracking: values.trackingRemiseDirecte,
                    idService: values.service,
                    idAgence: authData.idAgence,
                    idCompteCrud: authData.idCompte
                };

                const response = await axiosInstance.post(`${Visiteur}/RemiseColisDirecte`, postData);

            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "File d'attente existe déjà.") {
                    CustomToast("File d'attente existe déjà.", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Prefixe existe déjà.") {
                    CustomToast("Prefixe existe déjà.", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Une file d'attente active pour ce service existe déjà pour cette agence.") {
                    CustomToast("Une file d'attente active pour ce service existe déjà.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    useEffect(() => {
        localStorage.setItem('numTicketActuel', numTicketActuel);
        localStorage.setItem('idTicketActuel', idTicketActuel);
      }, [numTicketActuel, idTicketActuel]);

    // useEffect(() => {
    //     //Load Data
    //     getVisiteursAppele();

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData]);

    return (
        <div className="container appeler-section">
            <div className="wrap-col">
                {/* <h2>{authData?.numeroGuichet}</h2> */}
                <div className="contenue">
                    <h3>Numéro du dernier visiteur appelé: {numTicketActuel}</h3>
                    {numTicketActuel !== '000' && <Link to="#" className="btn btn-red-1" onClick={() => setShowAddModalRetour(true)}>{idTicketActuel === idTicketPre ? "Retour à la file" : "De retour dans la file"}</Link>}
                    <Link to="#" className="btn btn-white" onClick={() => setShowAddModalAppelerProchainVisiteur(true)}>Appeler le prochain visiteur</Link>
                    <Link to="#" className="btn btn-red-1" onClick={() => showHideModalAppelerVisiteur(true)}>Appeler par numéro</Link>
                    {/* <Link to="#" className="btn btn-white" onClick={() => showHideModalSuiviColis(true)}>Suivi de colis</Link> */}
                    <Link to="#" className="btn btn-white" onClick={() => showHideModalRemiseDirecte(true)}>Remise direct</Link>
                    <Link to="#" className="btn btn-red-1" onClick={() => showHideModalEnleverClient(true)}>Enlever les visiteurs de la file d'attente</Link>
                </div>
            </div>


            <ConfirmationDialog
                open={showAddModalAppelerProchainVisiteur}
                onClose={handleCancelProchainVisiteur}
                onConfirm={prochainVisiteur}
                titleMessage="Êtes-vous sûr ?"
                bodyMessage=""
                btnCancelText="Non"
                btnConfirmText="Oui"
            />

            <ConfirmationDialog
                open={showAddModalRetour}
                onClose={handleCancelRetour}
                onConfirm={retourVisiteur}
                titleMessage="Êtes-vous sûr ?"
                bodyMessage={`Le visiteur avec le numéro ${numTicketActuel} sera renvoyé à la file d’attente`}
                btnCancelText="Non"
                btnConfirmText="Oui"
            />

            {showAddModalAppelerVisiteur && <div className="popcard-add" id="prochaine-visiteur">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideModalAppelerVisiteur(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Choisir visiteur</h2>
                    <div className="popcard-add_inside-form">
                        {/* <form action=""> */}
                        <div className="wrap-info">
                            {visiteursAppele?.length > 0 && <div className="table-wrap scroll-height mb1">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>N° ticket</th>
                                            <th>Service</th>
                                            <th>Date</th>
                                            <th>Visiteur</th>
                                            <th>N° tél</th>
                                            <th>Tracking</th>
                                            <th>Appeler</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {visiteursAppele?.map((row) => (
                                            <tr key={row.idTicket}>
                                                <td>{row.numTicket}</td>
                                                <td>{row.libelleService}</td>
                                                <td>{format(row.dateArrive, "dd/MM/yyyy HH:mm")}</td>
                                                <td>{row.boutique}</td>
                                                <td>{row.numTel}</td>
                                                <td>{row.tracking}</td>
                                                <td><button className="submit-btn submit-btn-red" onClick={() => prochainVisiteurTab(row.idTicket)}>Appeler</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                        {visiteursAppeleErreur && <div className="wrap-row">
                            {visiteursAppeleErreur}
                        </div>}
                        <div className="popcard-operation-pickup-details_inside-zone">
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>}

            {/* {showAddModalSuiviColis && <div className="popcard-add" id="suivi-colis">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideModalSuiviColis(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        <form action="">
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <label>Tracking</label>
                                        <input type="text" />
                                    </div>
                                </div>
                                <div className="table-wrap">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Date du statut</th>
                                                <th>Statut</th>
                                                <th>Localisation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>10/01/2020</td>
                                                <td>active</td>
                                                <td>emplacement</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Valider" className="submit-btn submit-btn-red" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>} */}

            {showAddModalRemiseDirecte && <div className="popcard-add" id="remise-direct">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideModalRemiseDirecte(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                    <form onSubmit={formik1.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <label>Tracking<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="trackingRemiseDirecte"
                                            name="trackingRemiseDirecte"
                                            style={(formik1.touched.trackingRemiseDirecte && formik1.errors.trackingRemiseDirecte) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.trackingRemiseDirecte}
                                            onChange={formik1.handleChange}
                                            maxLength={10}
                                            minLength={10}
                                        />
                                        {formik1.touched.ltrackingRemiseDirecteibelle && formik1.errors.trackingRemiseDirecte ? (
                                            <div className="invalid-feedback">{formik1.errors.trackingRemiseDirecte}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Service<span className='requiredField'>*</span></label>
                                        <select
                                            id="service"
                                            name="service"
                                            value={formik1.values.service}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            <option value="1" defaultValue={true}>Récupérer un colis</option>
                                            <option value="5">Récupérer un retour</option>
                                        </select>
                                        {formik1.touched.service && formik1.errors.service ? (
                                        <div className="invalid-feedback">{formik1.errors.service}</div>
                                    ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Valider" className="submit-btn submit-btn-red" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            {showAddModalEnleverClient && <div className="popcard-add" id="retirer-visiteur">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideModalEnleverClient(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title"></h2>
                    <div className="popcard-add_inside-form">
                        {/* <form action=""> */}
                        <div className="wrap-info">
                            {visiteursAppele?.length > 0 && <div className="table-wrap scroll-height mb1">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>N° ticket</th>
                                            <th>Service</th>
                                            <th>Date</th>
                                            <th>Visiteur</th>
                                            <th>N° tél</th>
                                            <th>Tracking</th>
                                            <th>Enlever</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {visiteursAppele?.map((row) => (
                                            <tr key={row.idTicket}>
                                                <td>{row.numTicket}</td>
                                                <td>{row.libelleService}</td>
                                                <td>{format(row.dateArrive, "dd/MM/yyyy HH:mm")}</td>
                                                <td>{row.boutique}</td>
                                                <td>{row.numTel}</td>
                                                <td>{row.tracking}</td>
                                                <td><button className="submit-btn submit-btn-red" onClick={() => enleverTicket(row.idTicket)}>Enlever</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>}
                        </div>
                        {visiteursAppeleErreur && <div className="wrap-row">
                            {visiteursAppeleErreur}
                        </div>}
                        <div className="popcard-operation-pickup-details_inside-zone">
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default AppGuichet;
