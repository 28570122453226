import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import { ExportToExcel } from '../../components/ExportToExcel/ExportToExcel';
import Pagination from '../../components/pagination/Pagination';
import { axiosInstance, createCancelTokenSource, Agence, Stock } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';
import { tr } from 'date-fns/locale';

const ListeColis = () => {
    const { authData } = useContext(AuthContext);
    const [agences, setAgences] = useState([]);
    const [boxs, setBoxs] = useState([]);

    const [colis, setColis] = useState([]);
    const [filtredColis, setFiltredColis] = useState([]);

    const [filters, setFilters] = useState({
        idAgence: '',
        statut: '',
        idBox: ''
    });

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    //const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmDialogOpenDeleteColis, setConfirmDialogOpenDeleteColis] = useState(false);
    const [confirmDialogOpenDeleteColisMultiple, setConfirmDialogOpenDeleteColisMultiple] = useState(false);
    const [idColisDelete, setIdColisDelete] = useState('');
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [pathImporterColis, setPathImporterColis] = useState(null);
    const pathImporterColisRef = useRef(null);
    const [selectedColis, setSelectedColis] = useState([]);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...colis].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredColis(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = colis.slice(startIndex, endIndex);

        setFiltredColis(paginatedData);
        setSelectedColis([]);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Supprimer colis sorties
    const handleConfirmDelete = () => {
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Stock}/DeleteColis/${authData.idAgence}`);
            CustomToast("Les colis sortis ont été supprimés avec succès.", 'success');
            getListeColis(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Colis introuvables", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data

    const getListeColis = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            // setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            if (filters.statut !== '') {
                queryParams.append("statut", filters.statut);
            }

            if (filters.idBox !== '') {
                queryParams.append("idBox", filters.idBox);
            }

            // if (authData?.role != null && authData?.role !== "admin") {
            //     queryParams.append("idAgence", authData.idAgence);
            // } else {
            //     queryParams.append("idAgence", filters.idAgence);
            // }

            if (authData?.role != null && authData?.role !== "") {
                if (authData?.role !== "admin") {
                    queryParams.append("idAgence", authData.idAgence);
                } else {//admin
                    queryParams.append("idAgence", filters.idAgence);
                }
            } else {
                return;
            }

            const response = await axiosInstance.get(`${Stock}/GetListeColis?${queryParams}`, { cancelToken });
            const resp = await response.data;
            setColis(resp.data);
            setFiltredColis(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            // setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const getAgences = async () => {
        try {
            if (authData?.role != null && authData?.role === "admin") {
                const response = await axiosInstance.get(`${Agence}`);
                const resp = await response.data;
                setAgences(resp);
            } else if (authData?.role != null && authData?.role !== "admin") {
                const queryParams = new URLSearchParams({
                    idAgence: authData?.idAgence
                });

                const response = await axiosInstance.get(`${Agence}?${queryParams}`);
                const resp = await response.data;
                setFilters((prevValues) => ({
                    ...prevValues,
                    idAgence: authData.idAgence
                }));
                setAgences(resp);
            } else {

            }

        }
        catch (err) {
            console.log(err);
        }
    }

    const getBoxs = async (idAgence) => {
        try {
            if (authData?.role != null && authData?.role !== "admin") {
                const response = await axiosInstance.get(`${Stock}/GetBoxsByIdAgence/${authData.idAgence}`);
                const resp = await response.data;
                setBoxs(resp);
            } else {
                const response = await axiosInstance.get(`${Stock}/GetBoxsByIdAgence/${idAgence}`);
                const resp = await response.data;
                setBoxs(resp);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    //Exporter la lise des colis
    const exportExcel = async () => {
        try {
            const queryParams = new URLSearchParams({
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            if (filters.statut !== '') {
                queryParams.append("statut", filters.statut);
            }

            if (filters.idBox !== '') {
                queryParams.append("idBox", filters.idBox);
            }

            if (authData?.role != null && authData?.role !== "admin") {
                queryParams.append("idAgence", authData.idAgence);
            } else {
                queryParams.append("idAgence", filters.idAgence);
            }

            const response = await axiosInstance.get(`${Stock}/GetListeColisExcel?${queryParams}`);
            const resp = await response.data;

            const Heading = [
                [
                    "Agence",
                    "N° tracking",
                    "Indicateur de stock",
                    "Date entrée en stock",
                    "Date sortie de stock",
                    "Statut"
                ]
            ];

            let fileName = "Colis-" + format(new Date(), "yyyyMMddHHmmss");
            const fileExtension = ".xlsx";
            const blob = ExportToExcel(resp, fileName, fileExtension, "Colis", Heading);

            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            CustomToast(<><strong>Terminé : Exportation xlsx de liste des colis</strong><br></br><a href={url} download={fileName + fileExtension}>Cliquez ici si le téléchargement ne démarre pas</a></>, 'success', 60000);

            // Clean up the URL object after the download
            //window.URL.revokeObjectURL(url);         

        }
        catch (error) {
            console.log(error);
        }
    }

    //Upload documents
    const showHideUploadModal = (val) => {
        setShowUploadModal(val);
        //formik1.resetForm();
        setUploadError(false);
        setPathImporterColis(null);
    };

    const handlePathChange = (e) => {
        setPathImporterColis(e.target.files[0]);
    };

    const getImageFileName = (file) => {
        return file ? file.name : 'No file selected';
    };

    const formik1 = useFormik({
        initialValues: {
            idUtilisateur: '',
        },
        onSubmit: async (values) => {
            try {
                //console.log(values);
                const formData = new FormData();
                formData.append('filteToUpload', pathImporterColis);
                formData.append('idUtilisateur', authData.idCompte);

                const response = await axiosInstance.post(`${Stock}/ImporterColis`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                CustomToast("Le fichier a été importé avec succès", 'success');
                getListeColis(1, pageSize)
                setShowUploadModal(false);
                setUploadError(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Aucun fichier") {
                    CustomToast("Séléctionnez un fichier", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Template error") {
                    CustomToast("Veuillez vérifier votre fichier", 'error');
                    setUploadError(true)
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer un colis
    const handleConfirmDeleteColis = (idColis) => {
        setIdColisDelete(idColis);
        setConfirmDialogOpenDeleteColis(true);
    };

    const handleDeleteColis = async () => {
        try {
            const response = await axiosInstance.delete(`${Stock}/DeleteColisByIdColis/${idColisDelete}`);
            //getTypesDepenses();
            setIdColisDelete('');
            CustomToast("Le colis a été supprimé avec succès", 'success');
            getListeColis(1, pageSize);
            setConfirmDialogOpenDeleteColis(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Colis introuvables", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDeleteColis = () => {
        setConfirmDialogOpenDeleteColis(false);
    };

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getListeColis(1, pageSize); // Fetch inspections with updated filters
    };

    //Supprimer multiple

    const handleCheckboxChange = (colisId) => {
        setSelectedColis((prevSelectedColis) => {
            if (prevSelectedColis.includes(colisId)) {
                return prevSelectedColis.filter((id) => id !== colisId);
            } else {
                return [...prevSelectedColis, colisId];
            }
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allColisIds = filtredColis.map((colis) => colis.idColis);
            setSelectedColis(allColisIds);
        } else {
            setSelectedColis([]);
        }
    };

    const handleDeleteSelected = () => {
        setConfirmDialogOpenDeleteColisMultiple(true);
    };

    const confirmDeleteSelectedColisMultiple = async () => {
        try {
            const response = await axiosInstance.delete(`${Stock}/DeleteColisMultiple`, {
                data: selectedColis,
            });
            CustomToast("Les colis sélectionnés ont été supprimés avec succès.", 'success');
            getListeColis(1, pageSize);
            //console.log(selectedColis)
            setConfirmDialogOpenDeleteColisMultiple(false);
            setSelectedColis([]);
        } catch (error) {
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Aucun id de colis fourni.") {
                CustomToast("Aucun colis fourni.", 'error');
            } else if (error.response?.status === 404 && error.response?.data === "Aucun colis trouvé avec les id fournis.") {
                CustomToast("Colis introuvables", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échouée", 'error');
            }
            console.log(error);
        }
    };

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getListeColis(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getListeColis(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getListeColis(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText,filters]);

    useEffect(() => {
        //Load Data
        getAgences();

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        getBoxs(filters.idAgence);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [filters.idAgence]);

    return (
        <>
            <div className="container liste-colis-section">
                <div className="affectation-sec wrap-all">
                    <div className="filter-wrap">
                        <div className="filter-iner-wrap">
                            <select
                                id="idAgence"
                                name="idAgence"
                                className="input-filter"
                                value={filters.idAgence || ''}
                                onChange={(e) => setFilters({ ...filters, idAgence: e.target.value || '' })}
                            >
                                {authData?.role != null && authData?.role === "admin" && <option value="">Veuillez sélectionner</option>}
                                {agences.map((agence) => (
                                    <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                ))}
                            </select>
                            <select
                                id="idBox"
                                name="idBox"
                                className="input-filter"
                                value={filters.idBox || ''}
                                onChange={(e) => setFilters({ ...filters, idBox: e.target.value || '' })}
                            >
                                <option value="">Sélectionnez box</option>
                                {boxs.map((box) => (
                                    <option key={box.idBox} value={box.idBox}>{box.libelle}</option>
                                ))}
                            </select>
                            <select
                                id="statut"
                                name="statut"
                                className="input-filter"
                                value={filters.statut || ''}
                                onChange={(e) => setFilters({ ...filters, statut: e.target.value || '' })}
                            >
                                <option value="" defaultValue={true}>Sélectionnez statut</option>
                                <option value="entree">Entrée</option>
                                <option value="sortie">Sortie</option>
                            </select>

                            <Link to="#" type="submit" onClick={handleSearch}><span className="material-symbols-outlined search-btn">filter_alt</span></Link>
                        </div>
                        <div className='grp-btn'>
                            <button className="submit-btn submit-btn-green" type='button' onClick={() => exportExcel()}>
                                <span className="material-symbols-outlined search-btn">file_save</span> Exporter
                            </button>
                            <button className="submit-btn submit-btn-red" type='button' onClick={() => handleConfirmDelete()}>
                                <span className="material-symbols-outlined search-btn">delete</span> Supprimer le stock sorti
                            </button>
                            <button className="submit-btn submit-btn-green" type='button' onClick={() => showHideUploadModal(true)}>
                                <span className="material-symbols-outlined search-btn">upload_file</span> Sortir les colis
                            </button>
                            {selectedColis && selectedColis.length > 0 && <button className="submit-btn submit-btn-red" onClick={handleDeleteSelected}>
                                <span className="material-symbols-outlined search-btn">delete</span> Supprimer Sélection
                            </button>}
                        </div>
                    </div>
                    <div className="group-elements">
                        <div className="voir-elements">
                            <p>Voir</p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                        <div className="search-element">
                            {/* <p>rechercher</p> */}
                            <input
                                type="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredColis?.length > 0 && <div className="table-wrap-sticky">
                        <table>
                            <thead className="sticky-header">
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={selectedColis.length === filtredColis.length && selectedColis.length > 0}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    {authData?.role != null && authData?.role === "admin" && <th>Agence</th>}
                                    <th>N° Tracking</th>
                                    <th>Indicateur Stock</th>
                                    <th>Date Entrée</th>
                                    <th>Date Sortie</th>
                                    <th>Statut</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredColis?.map((row) => (
                                    <tr key={row.idColis}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedColis.includes(row.idColis)}
                                                onChange={() => handleCheckboxChange(row.idColis)}
                                            />
                                        </td>
                                        {authData?.role != null && authData?.role === "admin" && <td>{row.libelleAgence}</td>}
                                        <td>{row.tracking}</td>
                                        <td>{row.libelleBox}</td>
                                        <td>{format(row.dateEntreeStock, "dd/MM/yyyy HH:mm")}</td>
                                        <td>{row.dateSortieStock ? format(row.dateSortieStock, "dd/MM/yyyy HH:mm") : ''}</td>
                                        <td>{
                                            row.statut === "entree" ? <>Entrée</> :
                                                row.statut === "sortie" ? <>Sortie</> :
                                                    null
                                        }</td>
                                        <td className="txt-center" onClick={() => handleConfirmDeleteColis(row.idColis)}>
                                            <span className="material-symbols-outlined">delete</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {/* <div className="pagination">
                    <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                        className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                        disabled={(pageNumber === 1) ? true : false}
                    >&laquo;</Link>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <Link
                            key={i + 1}
                            to="#"
                            className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </Link>
                    ))}
                    <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                        className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                        disabled={((pageNumber + 1) > totalPages) ? true : false}
                    >&raquo;</Link>
                </div> */}
                    {filtredColis?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>

            </div>

            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer les colis sortis ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer les colis"
            />

            <ConfirmationDialog
                open={confirmDialogOpenDeleteColis}
                onClose={handleCancelDeleteColis}
                onConfirm={handleDeleteColis}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer ce colis ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer le colis"
            />

            <ConfirmationDialog
                open={confirmDialogOpenDeleteColisMultiple}
                onConfirm={confirmDeleteSelectedColisMultiple}
                onClose={() => setConfirmDialogOpenDeleteColisMultiple(false)}
                titleMessage="Confirmation de suppression"
                bodyMessage="Êtes-vous sûr de vouloir supprimer les colis sélectionnés ?"
                btnCancelText="Annuler"
                btnConfirmText="Supprimer les colis"
            />

            {showUploadModal && <div className="popcard-add" id="modifier-service">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" d="close-vehc" to="#" onClick={() => showHideUploadModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Upload excel</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit}>
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <label>Upload</label>
                                        <label className="upload-file">
                                            Upload
                                            <input
                                                ref={pathImporterColisRef}
                                                id="pathImporterColis"
                                                name="pathImporterColis"
                                                type="file"
                                                onChange={handlePathChange}
                                                accept=".xls,.xlsx"
                                            />
                                        </label>
                                        {pathImporterColis && <span style={{ fontSize: "10px" }}>{getImageFileName(pathImporterColis)}
                                            <span
                                                className="material-symbols-outlined"
                                                onClick={(e) => { setPathImporterColis(null); if (pathImporterColisRef.current) { pathImporterColisRef.current.value = ''; } }}>
                                                delete
                                            </span>
                                        </span>}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-full">
                                        <p>
                                            Le fichier doit être composé de :
                                            <ol>
                                                <li>
                                                    N°
                                                </li>
                                                <li>
                                                    Tracking
                                                </li>
                                            </ol>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default ListeColis;
