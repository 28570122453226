import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Outlet } from "react-router-dom"
import { format } from 'date-fns';
import { CustomToast } from '../Toast/CustomToast';
import { AuthContext } from '../../contexts/AuthContext';
const Layout = () => {
    const { authData, logout } = useContext(AuthContext);
    const [role, setRole] = useState(true);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    // Define your state variables for menu and submenu visibility
    const [menuActif, setMenuActif] = useState("");

    const updateSubMenuState = (field) => {
        setMenuActif(field);
        localStorage.setItem('menuShow', field);
    };

    const handleLogOut = () => {
        localStorage.removeItem('authData');
        localStorage.removeItem('menuShow');
        localStorage.removeItem('numTicketActuel');
        localStorage.removeItem('idTicketActuel');
        //localStorage.removeItem('menuShow');
        CustomToast(`Déconnexion réussie`, 'success');
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000); // Mettre à jour chaque seconde

        return () => {
            clearInterval(intervalId); // Nettoyer l'intervalle lors du démontage du composant
        };
    }, [authData]);

    useEffect(() => {
        setRole(authData?.role);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        setMenuActif(localStorage.getItem("menuShow"));
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, []);

    return (
        <>
            <div className="left">
                <ul>
                    <li className="menu-heading"><img src="img/yalidine-logo.png" alt="" /></li>
                    {(authData?.role === "admin" || authData?.role === "chef agence") && <li className={`overview ${menuActif === 'utilisateurs' ? 'active' : ''}`} onClick={() => { updateSubMenuState('utilisateurs'); }}>
                        <Link to="/utilisateurs" className="active overview">
                            <span className="material-symbols-outlined">person</span>
                            Utilisateurs
                        </Link>
                    </li>}
                    {authData?.role === "admin" && <li className={`Services ${menuActif === 'services' ? 'active' : ''}`} onClick={() => { updateSubMenuState('services'); }}>
                        <Link to="/services" className="Services">
                            <span className="material-symbols-outlined">info</span>
                            Services
                        </Link>
                    </li>}
                    {authData?.role === "admin" && <li className={`Agences ${menuActif === 'agences' ? 'active' : ''}`} onClick={() => { updateSubMenuState('agences'); }}>
                        <Link to="/agences" className="Agences">
                            <span className="material-symbols-outlined">real_estate_agent</span>
                            Agences
                        </Link>
                    </li>}
                    {(authData?.role === "admin" || authData?.role === "chef agence") && <li className={`Paramétrage ${menuActif === 'parametrageFa' ? 'active' : ''}`} onClick={() => { updateSubMenuState('parametrageFa'); }}>
                        <Link to="/fileattente" className="Paramétrage">
                            <span className="material-symbols-outlined">group</span>
                            Files d'attentes
                        </Link>
                    </li>}
                    {(authData?.role === "admin" || authData?.role === "chef agence") && <li className={`Guichets ${menuActif === 'guichets' ? 'active' : ''}`} onClick={() => { updateSubMenuState('guichets'); }}>
                        <Link to="/guichets" className="Guichets">
                            <span className="material-symbols-outlined">computer</span>
                            Guichets
                        </Link>
                    </li>}
                    {(authData?.role === "admin" || authData?.role === "chef agence") && <li className={`Matériels ${menuActif === 'materiels' ? 'active' : ''}`} onClick={() => { updateSubMenuState('materiels'); }}>
                        <Link to="/materiels" className="Matériels">
                            <span className="material-symbols-outlined">devices_other</span>
                            Matériels
                        </Link>
                    </li>}
                    {(authData?.role === "admin" || authData?.role === "chef agence") && <li className={`paramètres-application ${menuActif === 'agence' ? 'active' : ''}`} onClick={() => { updateSubMenuState('agence'); }}>
                        <Link to="/agenceparams" className="paramètres-application">
                            <span className="material-symbols-outlined">real_estate_agent</span>
                            Agence
                        </Link>
                    </li>}

                    {(authData?.role === "admin" || authData?.role === "agent guichet") && <li className={`appeler ${menuActif === 'appeler' ? 'active' : ''}`} onClick={() => { updateSubMenuState('appeler'); }}>
                        <Link to="/appguichet" className="appeler">
                            <span className="material-symbols-outlined">call</span>
                            Appeler
                        </Link>
                    </li>}
                    {(authData?.role === "admin" || authData?.role === "chef agence") && <li className={`paramètres-stock ${menuActif === 'stockparams' ? 'active' : ''}`} onClick={() => { updateSubMenuState('stockparams'); }}>
                        <Link to="/stockparams" className="paramètres-stock">
                            <span className="material-symbols-outlined">inventory_2</span>
                            Paramétrage stock
                        </Link>
                    </li>}
                    {(authData?.role === "admin" || authData?.role === "gestionnaire stock" || authData?.role === "chef agence") && <li className={`affectation-colis ${menuActif === 'affectationcolis' ? 'active' : ''}`} onClick={() => { updateSubMenuState('affectationcolis'); }}>
                        <Link to="/affectationcolis" className="affectation-colis">
                            <span className="material-symbols-outlined">arrow_forward</span>
                            Affectation colis
                        </Link>
                    </li>}
                    {(authData?.role === "admin" || authData?.role === "gestionnaire stock" || authData?.role === "chef agence") && <li className={`liste-colis ${menuActif === 'listecolis' ? 'active' : ''}`} onClick={() => { updateSubMenuState('listecolis'); }}>
                        <Link to="/listecolis" className="liste-colis">
                            <span className="material-symbols-outlined">receipt_long</span>
                            Liste colis
                        </Link>
                    </li>}
                    {(authData?.role === "admin" || authData?.role === "gestionnaire stock" || authData?.role === "chef agence") && <li className={`liste-colis ${menuActif === 'demandes' ? 'active' : ''}`} onClick={() => { updateSubMenuState('demandes'); }}>
                        <Link to="/demandes" className="liste-colis">
                            <span className="material-symbols-outlined">badge</span>
                            Demandes
                        </Link>
                    </li>}
                    {(authData?.role === "materiel" || authData?.role === "admin") && <li className={`inter-affichage ${menuActif === 'affichage' ? 'active' : ''}`} onClick={() => { updateSubMenuState('affichage'); }}>
                        <Link to="/ecran" className="liste-colis">
                            <span className="material-symbols-outlined">dashboard</span>
                            Affichage
                        </Link>
                    </li>}
                    <li className="Paramétrage" onClick={() => { updateSubMenuState(''); }}>
                        <Link to="/login" className="Paramétrage" replace onClick={() => handleLogOut()}>
                            <span className="material-symbols-outlined">logout</span>
                            Se déconnecter
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="right">
                <div className="tab-content">
                    <div id="overview">
                        <div className="header">
                            {authData?.role !== "materiel" &&
                                <h4>{(authData?.role !== "agent guichet") ? (authData?.nom + ' ' + authData?.prenom) : authData?.numeroGuichet}
                                </h4>}

                            <h4>
                                <p>{authData?.libelleAgenceTicket}</p>
                            </h4>

                            {/* {authData?.role === "materiel" &&  <h4>
                                <p>{authData?.libelleAgenceTicket}</p>
                            </h4>} */}
                            {/* {authData?.role === "materiel" && <h4>
                                <p>{format(currentDateTime, "dd/MM/yyyy")}</p>
                                <p>{format(currentDateTime, "HH:mm:ss")}</p>
                            </h4>} */}
                        </div>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Layout;
