import React, { useEffect, useState, useContext } from 'react';
import { format } from 'date-fns';
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, createCancelTokenSource, Agence, Stock, Guichet,AppHub } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import { isConnectionConnecting, createConnection, joinGroup } from '../../services/SignalRService';

const Ecran = () => {
    const { authData } = useContext(AuthContext);
    const [connexionDashboard, setConnexionDashboard] = useState(createConnection(AppHub));
    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    const [guichets, setGuichets] = useState([]);
    //Load data
    const AffichageEcran = async () => {
        try {
            if (authData?.idAgence === "" || authData?.idAgence === null) {
                return;
            }
            const response = await axiosInstance.get(`${Guichet}/AffichageEcran/${authData?.idAgence}`);
            const resp = await response.data;
            setGuichets(resp);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400) {
                CustomToast("Guichet introuvable.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000); // Mettre à jour chaque seconde

        return () => {
            clearInterval(intervalId); // Nettoyer l'intervalle lors du démontage du composant
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        //console.log(authData?.idAgence)
        if (authData?.idAgence) {
            AffichageEcran();
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        if (isConnectionConnecting(connexionDashboard)) {
            console.log("isConnectionConnecting")
            connexionDashboard.on('ProchainTicket', () => {
                // connexionDashboard.invoke("GetFlotteCount", authData?.wilayas.join(','), authData?.flottesAutorises.join(','))
                //     .catch(error => console.error(error));
                AffichageEcran();
            });
        }

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //connexionDashboard.stop();
        };
    }, [authData]);
    return (
        <div className="container inter-affichage-section">
                    <div className="container grid grid--4-cols">
                        <div className="show-section">
                            <img src="img/yal88.jpg" alt="" style={{width: "100%", height:"100%"}}/>
                        </div>
                        <div className="user-affichage-section">
                        <div className='flex-row-collect'>
                            {guichets && <div className="col">
                                <div className="sec">
                                    <div className="col">
                                        <div className="flex-row">
                                            <h3 className="">Ticket</h3>
                                            <h3>Guichet</h3>
                                        </div>
                                        <div className="flex-row">
                                            <h3 className="">تذكرة</h3>
                                            <h3>شباك</h3>
                                        </div>
                                    </div>
                                </div>
                                {guichets?.map((row) => (
                                    <div className="sec" key={row.idGuichet}>
                                        <div className="col">
                                            <div className="flex-row">
                                                <h2 className="number-spec">{row.numeroTicket?row.numeroTicket:''}</h2>
                                                <h3 className="number-circle">{row.numeroGuichet?row.numeroGuichet:''}</h3>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>}
                        </div>
                        </div>
                    </div>
                </div>
    );
}

export default Ecran;
