import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns'
import { CustomToast } from '../../components/Toast/CustomToast';
// import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import Pagination from '../../components/pagination/Pagination';
import { degrees, grayscale, PDFDocument, PDFFont, rgb, StandardFonts } from 'pdf-lib';
// import qrCode from 'qrcode';
import { axiosInstance, createCancelTokenSource, Agence, Stock, Visiteur, AppHub } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';
import fontkit from '@pdf-lib/fontkit';
import AmiriFont from '../../fonts/El_Messiri/ElMessiri-VariableFont_wght.ttf';
import { isConnectionConnecting, createConnection, joinGroup } from '../../services/SignalRService';

const Demandes = () => {
    const { authData } = useContext(AuthContext);
    const [connexionDashboard, setConnexionDashboard] = useState(createConnection(AppHub));
    const [agences, setAgences] = useState([]);
    const [colis, setColis] = useState([]);

    const [demandes, setDemandes] = useState([]);
    const [filtredDemandes, setFiltredDemandes] = useState([]);

    const [filters, setFilters] = useState({
        idAgence: '',
        statut: 'en attente',
        dateDemandeDebut: format(new Date(), "yyyy-MM-dd"),
        dateDemandeFin: format(new Date(), "yyyy-MM-dd")
    });

    const [showPrintModal, setShowPrintModal] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    //const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    const [idServiceSelected, setIdServiceSelected] = useState('');
    const [idPassageSelected, setIdPassageSelected] = useState('');
    const [statutSelected, setStatutSelected] = useState('');

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...demandes].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredDemandes(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = demandes.slice(startIndex, endIndex);

        setFiltredDemandes(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Load data
    const getListeDemandes = async (page, newPerPage) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            // setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }
            if (filters.statut !== '') {
                queryParams.append("statut", filters.statut);
            }

            // if (authData?.role != null && authData?.role !== "admin") {
            //     queryParams.append("idAgence", authData.idAgence);
            // } else {
            //     queryParams.append("idAgence", filters.idAgence);
            // }

            if (authData?.role != null  && authData?.role !== "") {
                if (authData?.role !== "admin") {
                    queryParams.append("idAgence", authData.idAgence);
                }else{//admin
                    queryParams.append("idAgence", filters.idAgence);
                }                
            }else{
                return;
            }

            if (filters.dateDemandeDebut != null) {
                const formattedStartDate = format(filters.dateDemandeDebut, "yyyy-MM-dd");
                queryParams.append("dateDemandeDebut", formattedStartDate);
            }

            if (filters.dateDemandeFin != null) {
                const formattedEndDate = format(filters.dateDemandeFin, "yyyy-MM-dd");
                queryParams.append("dateDemandeFin", formattedEndDate);
            }

            const response = await axiosInstance.get(`${Visiteur}/GetListeDemandes?${queryParams}`);
            const resp = await response.data;
            setDemandes(resp.data);
            setFiltredDemandes(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            // setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const getAgences = async () => {
        try {
            if (authData?.role != null && authData?.role === "admin") {
                const response = await axiosInstance.get(`${Agence}`);
                const resp = await response.data;
                setAgences(resp);
            } else if (authData?.role != null && authData?.role !== "admin") {
                const queryParams = new URLSearchParams({
                    idAgence: authData?.idAgence
                });

                const response = await axiosInstance.get(`${Agence}?${queryParams}`);
                const resp = await response.data;
                setFilters((prevValues) => ({
                    ...prevValues,
                    idAgence: authData.idAgence
                }));
                setAgences(resp);
            } else {

            }

        }
        catch (err) {
            console.log(err);
        }
    }

    //Imprimer etiquettes

    const printEtiquette1 = async (tracking, numeroTicket, libelleService, boutique, numTel, idAgence, idService) => {
        // Créer un nouvel objet PDFDocument
        // if(tracking==="" || tracking==null){
        //     CustomToast("Tracking obligatoire", 'error');
        //     return;
        // }
        const pdfDoc = await PDFDocument.create();
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // Créer une nouvelle page au format 6,0 cm × 4,0 cm
        const page = pdfDoc.addPage([60, 40]);

        page.drawText(`N° ticket: ${numeroTicket}`, { x: 5, y: 35, size: 4, color: rgb(0, 0, 0) });
        page.drawText(`Boutique: ${boutique}`, { x: 5, y: 30, size: 4, color: rgb(0, 0, 0) });
        page.drawText(`N° tél: ${numTel}`, { x: 5, y: 25, size: 4, color: rgb(0, 0, 0) });
        page.drawText(`Service: ${libelleService}`, { x: 5, y: 20, size: 4, color: rgb(0, 0, 0) });
        page.drawText(`Tracking: ${tracking}`, { x: 5, y: 15, size: 4, color: rgb(0, 0, 0) });
        if (idService === 1) {
            const queryParams = new URLSearchParams({});

            queryParams.append("idService", idService);
            queryParams.append("idAgence", idAgence);
            queryParams.append("boutique", boutique);
            queryParams.append("tracking", tracking);

            const response = await axiosInstance.get(`${Visiteur}/GetDemande?${queryParams}`);
            const resp = await response.data;
            //console.log(resp[0].libelleBox);
            if (response?.status === 200 && resp === "Colis n'existe pas dans le stock") {
                page.drawText(`Emplacement: `, { x: 5, y: 10, size: 4, color: rgb(0, 0, 0) });
                CustomToast("Colis n'existe pas dans le stock", 'warning');
            } else {
                // page.drawText(`Emplacement: ${resp[0]?.libelleBox}`, { x: 5, y: 10, size: 4, color: rgb(0, 0, 0) });
                const libelleBox = resp[0]?.libelleBox;
                if (libelleBox) {
                    page.drawText(`Emplacement: ${libelleBox}`, { x: 5, y: 10, size: 4, color: rgb(0, 0, 0) });
                } else {
                    page.drawText(`Emplacement: `, { x: 5, y: 10, size: 4, color: rgb(0, 0, 0) });
                }
            }
        }

        // Enregistrez le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');

    };

    //Action: liste tracking/emplacement
    const showHidePrintModal = (val, tracking, numeroTicket, libelleService, boutique, numTel, idAgence, idService, idPassage, statut) => {
        setShowPrintModal(val);
        setIdServiceSelected(idService);
        setIdPassageSelected(idPassage);
        setStatutSelected(statut);
        //console.log(idPassage)
        if (val) {
            if (statut === "en attente") {
                getColis(idService, idAgence, boutique, tracking);
            }

            if (statut === "traité") {
                getPassage(idPassage);
            }
        }
    };

    const getColis = async (idService, idAgence, boutique, tracking) => {
        try {

            // if(tracking==="" || tracking==null){
            //     //CustomToast("Tracking obligatoire", 'error');
            //     return;
            // }

            const queryParams = new URLSearchParams({});

            queryParams.append("idService", idService);
            queryParams.append("idAgence", idAgence);
            queryParams.append("boutique", boutique);
            //queryParams.append("idBoutique", idBoutique);           
            queryParams.append("tracking", tracking);

            const response = await axiosInstance.get(`${Visiteur}/GetDemande?${queryParams}`);
            const resp = await response.data;
            if (response?.status === 200 && resp === "Colis n'existe pas dans le stock") {
                //CustomToast("Colis n'existe pas dans le stock", 'warning');
                setColis([]);
            } else {
                setColis(resp);
            }

        }
        catch (err) {
            setColis([]);
            console.log(err);
        }
    }

    const getPassage = async (idPassge) => {
        try {
            const queryParams = new URLSearchParams({});

            queryParams.append("idPassage", idPassge);

            const response = await axiosInstance.get(`${Visiteur}/GetDemandeByIdPassage?${queryParams}`);
            const resp = await response.data;
            setColis(resp);
        }
        catch (err) {
            setColis([]);
            console.log(err);
        }
    }

    const valider = async () => {
        try {
            //Mettre a jour statut demande (passage) vers traité
            const postData = {
                idPassage: idPassageSelected,
                idService: idServiceSelected,
                //trackings: colis.map(colis => colis.tracking),
                colis: colis,
                idCompteCrud: authData.idCompte
            };

            const response = await axiosInstance.put(`${Visiteur}/UpdatePassage`, postData);
            CustomToast("La demande a été modifiée avec succès", 'success');
            getListeDemandes(pageNumber, pageSize);
            showHidePrintModal(false, '', '', '', '', '', '', '', '', '');

            setColis([]);
        }
        catch (error) {
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Demande introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    };

    const validerImprimer = async () => {
        try {
            //Mettre a jour statut demande (passage) vers traité
            const postData = {
                idPassage: idPassageSelected,
                idService: idServiceSelected,
                //trackings: colis.map(colis => colis.tracking),
                colis: colis,
                idCompteCrud: authData.idCompte
            };

            const response = await axiosInstance.put(`${Visiteur}/UpdatePassage`, postData);
            CustomToast("La demande a été modifiée avec succès", 'success');
            getListeDemandes(pageNumber, pageSize);
            showHidePrintModal(false, '', '', '', '', '', '', '', '', '');
            //Imprimer pdf service
            if (idServiceSelected === 1) {//Récupérer un colis
                createPDFListeColisRecuperation();
            }

            if (idServiceSelected === 5) {//Récupérer un retour
                createPDFListeColisRetour()
            }
            setColis([]);
        }
        catch (error) {
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Demande introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    };

    const imprimer = async () => {
        try {
            showHidePrintModal(false, '', '', '', '', '', '', '', '', '');
            //Imprimer pdf service
            if (idServiceSelected === 1) {//Récupérer un colis
                createPDFListeColisRecuperation();
            }

            if (idServiceSelected === 5) {//Récupérer un retour
                createPDFListeColisRetour()
            }
            setColis([]);
        }
        catch (error) {
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Demande introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    };

    function groupBy(arr, key) {
        return arr.reduce((acc, obj) => {
            const groupKey = obj[key];
            acc[groupKey] = acc[groupKey] || [];
            acc[groupKey].push(obj);
            return acc;
        }, {});
    }

    const createPDFListeColisRetour = async () => {
        const groupedByClient = groupBy(colis, 'boutique');

        const fontBytes = await fetch(AmiriFont).then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);
        const customFont = await pdfDoc.embedFont(fontBytes);
        // Créer une nouvelle page
        let page = pdfDoc.addPage([210, 297]);

        const maxLinesPerPage = 40; // Choisissez un nombre maximal de lignes par page
        let linesOnCurrentPage = 0;
        let pageNumber = 1; // Numéro de la page actuelle

        page.drawText(`Page ${pageNumber}`, {
            x: 180,
            y: 10,
            size: 3,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Utilisateur: ", {
            x: 5,
            y: 280,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(authData.nom + " " + authData.prenom, {
            x: 25,
            y: 280,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        linesOnCurrentPage++;

        page.drawText(format(new Date(), "dd/MM/yyyy HH:mm"), {
            x: 5,
            y: 275,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        linesOnCurrentPage++;

        let offsetY = 265; // Initial y-coordinate for client sections
        for (const clientName in groupedByClient) {
            if (groupedByClient.hasOwnProperty(clientName)) {
                const clientData = groupedByClient[clientName];
                const groupedByZone = groupBy(clientData, 'libelleZone');

                page.drawText(`${clientName}`, {
                    x: 10,
                    y: offsetY,
                    size: 7,
                    font: customFont,
                    color: rgb(0, 0, 0),
                    direction: 'rtl',
                });

                linesOnCurrentPage++;
                offsetY -= 5;

                if (linesOnCurrentPage > maxLinesPerPage) {
                    // Crée une nouvelle page si nécessaire
                    page = pdfDoc.addPage([210, 297]);
                    linesOnCurrentPage = 0;
                    offsetY = 265;
                    pageNumber++;
                    page.drawText(`Page ${pageNumber}`, {
                        x: 180,
                        y: 10,
                        size: 3,
                        font: customFont,
                        color: rgb(0, 0, 0),
                    });
                }

                for (const zoneKey in groupedByZone) {
                    if (groupedByZone.hasOwnProperty(zoneKey)) {
                        const zoneData = groupedByZone[zoneKey];
                        const groupedByBox = groupBy(zoneData, 'libelleBox');

                        for (const boxKey in groupedByBox) {
                            if (groupedByBox.hasOwnProperty(boxKey)) {
                                const boxData = groupedByBox[boxKey];
                                const groupedByEtagere = groupBy(boxData, 'libelleEtagere');

                                for (const etagereKey in groupedByEtagere) {
                                    if (groupedByEtagere.hasOwnProperty(etagereKey)) {
                                        const etagereData = groupedByEtagere[etagereKey];
                                        const groupedByLibelle = groupBy(etagereData, 'libelleBoite');

                                        for (const libelleKey in groupedByLibelle) {
                                            if (groupedByLibelle.hasOwnProperty(libelleKey)) {
                                                const libelleData = groupedByLibelle[libelleKey];

                                                page.drawText(`${zoneKey}/${boxKey}/${etagereKey}/${libelleKey}`, {
                                                    x: 20,
                                                    y: offsetY,
                                                    size: 5,
                                                    font: customFont,
                                                    color: rgb(0, 0, 0),
                                                    wrap: 180,
                                                });

                                                linesOnCurrentPage++;
                                                offsetY -= 5;
                                                if (linesOnCurrentPage > maxLinesPerPage) {
                                                    // Crée une nouvelle page si nécessaire
                                                    page = pdfDoc.addPage([210, 297]);
                                                    linesOnCurrentPage = 0;
                                                    offsetY = 265;
                                                    pageNumber++;
                                                    page.drawText(`Page ${pageNumber}`, {
                                                        x: 180,
                                                        y: 10,
                                                        size: 3,
                                                        font: customFont,
                                                        color: rgb(0, 0, 0),
                                                    });
                                                }

                                                for (const colisItem of libelleData) {
                                                    // Ajoutez votre logique d'affichage ici, par exemple :
                                                    //console.log(`Client: ${clientName}, Zone: ${zoneKey}, Box: ${boxKey}, Etagere: ${etagereKey}, Libelle: ${libelleKey}, Tracking: ${colisItem.tracking}`);
                                                    //offsetY -= 5;
                                                    page.drawText(`${colisItem.tracking}`, {
                                                        x: 50,
                                                        y: offsetY,
                                                        size: 4,
                                                        font: customFont,
                                                        color: rgb(0, 0, 0),
                                                        wrap: 180,
                                                    });

                                                    linesOnCurrentPage++;
                                                    offsetY -= 5;
                                                    if (linesOnCurrentPage > maxLinesPerPage) {
                                                        // Crée une nouvelle page si nécessaire
                                                        page = pdfDoc.addPage([210, 297]);
                                                        linesOnCurrentPage = 0;
                                                        offsetY = 265;
                                                        pageNumber++;
                                                        page.drawText(`Page ${pageNumber}`, {
                                                            x: 180,
                                                            y: 10,
                                                            size: 3,
                                                            font: customFont,
                                                            color: rgb(0, 0, 0),
                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Save the PDF to a new byte array
        const modifiedPdfBytes = await pdfDoc.save();

        // Create a Blob object from the PDF bytes
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Create a data URL for the Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(pdfUrl, '_blank');
    };

    const createPDFListeColisRecuperation = async () => {
        const groupedByClient = groupBy(colis, 'boutique');

        const fontBytes = await fetch(AmiriFont).then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);
        const customFont = await pdfDoc.embedFont(fontBytes);
        // Créer une nouvelle page
        let page = pdfDoc.addPage([210, 297]);

        const maxLinesPerPage = 40; // Choisissez un nombre maximal de lignes par page
        let linesOnCurrentPage = 0;
        let pageNumber = 1; // Numéro de la page actuelle

        page.drawText(`Page ${pageNumber}`, {
            x: 180,
            y: 10,
            size: 3,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Utilisateur: ", {
            x: 5,
            y: 280,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(authData.nom + " " + authData.prenom, {
            x: 25,
            y: 280,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(format(new Date(), "dd/MM/yyyy HH:mm"), {
            x: 5,
            y: 275,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Tracking: ${colis[0].tracking}`, {
            x: 5,
            y: 270,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Emplacement: ${colis[0].libelleBox}`, {
            x: 5,
            y: 265,
            size: 4,
            font: customFont,
            color: rgb(0, 0, 0),
        });


        // Save the PDF to a new byte array
        const modifiedPdfBytes = await pdfDoc.save();

        // Create a Blob object from the PDF bytes
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Create a data URL for the Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Open the PDF in a new window or tab
        window.open(pdfUrl, '_blank');
    };

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getListeDemandes(1, pageSize); // Fetch inspections with updated filters
    };

    useEffect(() => {
        //Load Data
        getListeDemandes(pageNumber, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        getListeDemandes(1, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getListeDemandes(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);

    useEffect(() => {
        //Load Data
        getAgences();

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData]);

    useEffect(() => {
        //Load Data
        if (isConnectionConnecting(connexionDashboard)) {
            console.log("isConnectionConnecting")
            connexionDashboard.on('TicketCreated', () => {
                // connexionDashboard.invoke("GetFlotteCount", authData?.wilayas.join(','), authData?.flottesAutorises.join(','))
                //     .catch(error => console.error(error));
                getListeDemandes(pageNumber, pageSize);
            });
        }

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            //connexionDashboard.stop();
        };
    }, [authData]);
    return (
        <div className="container liste-colis-section">
            <div className="affectation-sec wrap-all">
                <div className="filter-wrap">
                    <div className="filter-iner-wrap">
                        <select
                            id="idAgence"
                            name="idAgence"
                            className="input-filter"
                            value={filters.idAgence || ''}
                            onChange={(e) => setFilters({ ...filters, idAgence: e.target.value || '' })}
                        >
                            {authData?.role != null && authData?.role === "admin" && <option value="">Veuillez sélectionner</option>}
                            {agences.map((agence) => (
                                <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                            ))}
                        </select>
                        <input
                            type="date"
                            value={filters.dateDemandeDebut}
                            className="input-filter"
                            onChange={(e) => setFilters({ ...filters, dateDemandeDebut: e.target.value })}
                        />
                        <input
                            type="date"
                            value={filters.dateDemandeFin}
                            className="input-filter"
                            onChange={(e) => setFilters({ ...filters, dateDemandeFin: e.target.value })}
                        />
                        <select
                            id="statut"
                            name="statut"
                            className="input-filter"
                            value={filters.statut || ''}
                            onChange={(e) => setFilters({ ...filters, statut: e.target.value || '' })}
                        >
                            <option value="" defaultValue={true}>Sélectionnez statut</option>
                            <option value="en attente">En attente</option>
                            <option value="traité">Traité</option>
                            <option value="enlevé">Enlevé</option>
                        </select>
                        <Link to="#" type="submit" onClick={handleSearch}><span className="material-symbols-outlined search-btn">filter_alt</span></Link>

                    </div>
                </div>
                <div className="group-elements">
                    <div className="voir-elements">
                        <p>Voir</p>
                        <select
                            name="pageSize"
                            id="pageSize"
                            onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                        >
                            <option value="10" defaultValue={true}>10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                    </div>
                    <div className="search-element">
                        {/* <p>rechercher</p> */}
                        <input
                            type="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                {filtredDemandes?.length > 0 && <div className="table-wrap">
                    <table>
                        <thead>
                            <tr>
                                {authData?.role != null && authData?.role === "admin" && <th>Agence</th>}
                                <th>N° tracking</th>
                                <th>Boutique</th>
                                <th>N° téléphone</th>
                                <th>Service</th>
                                <th>N° ticket</th>
                                <th>Statut</th>
                                <th>Imprimer étiquette</th>
                                <th>Imprimer emplacement</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filtredDemandes?.map((row) => (
                                <tr key={row.idPassage}>
                                    {authData?.role != null && authData?.role === "admin" && <td>{row.libelleAgence}</td>}
                                    <td>{row.tracking}</td>
                                    <td>{row.boutique}</td>
                                    <td>{row.numTel}</td>
                                    <td>{row.libelleService}</td>
                                    <td>{row.numTicket}</td>
                                    <td>{
                                        row.statut === "en attente" ? <>En attente</> :
                                            row.statut === "enlevé" ? <>Enlevé</> :
                                                row.statut === "traité" ? <>Traité</> :
                                                    null
                                    }</td>
                                    <td><Link to="#" onClick={() => printEtiquette1(row.tracking, row.numTicket, row.libelleService, row.boutique, row.numTel, row.idAgence, row.idService)}>
                                        <span className="material-symbols-outlined danger">
                                            print</span></Link></td>
                                    <td><Link to="#" onClick={() => showHidePrintModal(true, row.tracking, row.numTicket, row.libelleService, row.boutique, row.numTel, row.idAgence, row.idService, row.idPassage, row.statut)}>
                                        <span className="material-symbols-outlined danger">
                                            print</span></Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
                {/* <div className="pagination">
                    <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                        className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                        disabled={(pageNumber === 1) ? true : false}
                    >&laquo;</Link>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <Link
                            key={i + 1}
                            to="#"
                            className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </Link>
                    ))}
                    <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                        className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                        disabled={((pageNumber + 1) > totalPages) ? true : false}
                    >&raquo;</Link>
                </div> */}
                {filtredDemandes?.length > 0 && <Pagination
                    pageNumber={pageNumber}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                    pageSize={pageSize}
                    totalItems={totalItems}
                />}
            </div>

            {showPrintModal && <div className="popcard-add" id="print-stock">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHidePrintModal(false, '', '', '', '', '', '', '', '', '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Imprimer les emplacements</h2>
                    <div className="popcard-add_inside-form">
                        <div className="wrap-info">
                            {colis?.length > 0 && <div className="table-wrap scroll-height mb1">
                                <table>
                                    <thead>
                                        <tr>
                                            {idServiceSelected === 5 && <th>Boutique</th>}
                                            <th>Tracking</th>
                                            <th>Emplacement</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {colis?.map((row) => (
                                            <tr key={row.tracking}>
                                                {idServiceSelected === 5 && <th>{row.boutique}</th>}
                                                <td>{row.tracking}</td>
                                                <td>{idServiceSelected === 5 ? `${row.libelleZone}/${row.libelleBox}/${row.libelleEtagere}/${row.libelleBoite}` : row.libelleBox}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>}
                            {colis?.length === 0 &&
                                <h3>Colis introuvable</h3>
                            }
                        </div>

                        <div className="popcard-operation-pickup-details_inside-zone">
                            <div className="buttons">
                                {(statutSelected === "en attente" && colis?.length > 0) && <input type="submit" value="Valider et imprimer" className="submit-btn submit-btn-green" onClick={() => validerImprimer()} />}
                                {(statutSelected === "en attente" && colis?.length === 0) && <input type="submit" value="Valider" className="submit-btn submit-btn-green" onClick={() => valider()} />}
                                {(statutSelected === "traité" && colis?.length > 0) && <input type="submit" value="Imprimer" className="submit-btn submit-btn-red" onClick={() => imprimer()} />}
                                <input type="submit" value="Annuler" className="submit-btn submit-btn-red" onClick={() => showHidePrintModal(false, '', '', '', '', '', '', '', '')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>

    );
}

export default Demandes;
