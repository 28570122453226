import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import Pagination from '../../components/pagination/Pagination';
import { axiosInstance, createCancelTokenSource, Agence, Materiel } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';

const Materiels = () => {

    const { authData } = useContext(AuthContext);
    const [materiels, setMateriels] = useState([]);
    const [filtredMateriels, setFiltredMateriels] = useState([]);
    const [agences, setAgences] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idMaterielDelete, setIdMaterielDelete] = useState('');
    const [idMaterielUpdate, setIdMaterielUpdate] = useState('');

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredMateriels].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredMateriels(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = materiels.slice(startIndex, endIndex);

        setFiltredMateriels(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Ajouter matériel
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
        formik1.values.statut = 'actif';
        getAgences()
    };

    const formik1 = useFormik({
        initialValues: {
            libelle: '',
            numeroSerie: '',
            type: '',
            statut: 'actif',
            idAgence: ''
        },
        validationSchema: Yup.object({
            libelle: Yup.string()
                .required('Champ obligatoire'),
            type: Yup.string()
                .required('Champ obligatoire'),
            statut: Yup.string()
                .required('Champ obligatoire'),
            idAgence: Yup.string()
                .required('Champ obligatoire'),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    libelle: values.libelle,
                    numeroSerie: values.numeroSerie,
                    type: values.type,
                    statut: values.statut,
                    idAgence: values.idAgence,
                    idCompteCrud: authData.idCompte
                };
                //console.log(values)
                const response = await axiosInstance.post(Materiel, postData);
                CustomToast("Le matériel a été créé avec succès", 'success');
                getMateriels(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un matériel avec le même nom existe déjà.") {
                    CustomToast("Un matériel avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier matériel
    const showHideUpdateModal = (val, idMateriel, libelle, numeroSerie, type, statut, idAgence, idCompte) => {
        setShowUpdateModal(val);
        formik2.resetForm();
        getAgences();
        if (val) {
            formik2.setValues((prevValues) => ({
                ...prevValues,
                idMateriel: idMateriel,
                libelleUpdate: libelle,
                numeroSerieUpdate: numeroSerie,
                typeUpdate: type,
                statutUpdate: statut,
                idAgenceUpdate: idAgence,
                idCompteUpdate: idCompte,
            }));

        }
    };

    const formik2 = useFormik({
        initialValues: {
            idMateriel: '',
            libelleUpdate: '',
            numeroSerieUpdate: '',
            typeUpdate: '',
            statutUpdate: 'actif',
            idAgenceUpdate: '',
            idCompteUpdate: '',
        },
        validationSchema: Yup.object({
            libelleUpdate: Yup.string()
                .required('Champ obligatoire'),
            typeUpdate: Yup.string()
                .required('Champ obligatoire'),
            statutUpdate: Yup.string()
                .required('Champ obligatoire'),
            idAgenceUpdate: Yup.string()
                .required('Champ obligatoire'),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idMateriel: values.idMateriel,
                    libelle: values.libelleUpdate,
                    numeroSerie: values.numeroSerieUpdate,
                    type: values.typeUpdate,
                    statut: values.statutUpdate,
                    idAgence: values.idAgenceUpdate,
                    idCompte: values.idCompteUpdate,
                    idCompteCrud: authData.idCompte
                };

                const response = await axiosInstance.put(Materiel, postData);
                CustomToast("Le matériel a été modifié avec succès", 'success');
                getMateriels(pageNumber, pageSize);
                setShowUpdateModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un matériel avec le même nom existe déjà.") {
                    CustomToast("Un matériel avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 404 && error.response?.data === "Le matériel à mettre à jour n'existe pas.") {
                    CustomToast("Le matériel à mettre à jour n'existe pas.", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer un matériel
    const handleConfirmDelete = (idMateriel) => {
        setIdMaterielDelete(idMateriel);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Materiel}/${idMaterielDelete}`);
            setIdMaterielDelete('');
            CustomToast("L'utilisateur a été supprimé avec succès", 'success');
            getMateriels(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Matériel introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data

    const getMateriels = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            // setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            // if (authData?.role != null && authData?.role !== "admin") {
            //     queryParams.append("idAgence", authData.idAgence);
            // }

            if (authData?.role != null  && authData?.role !== "") {
                if (authData?.role !== "admin") {
                    queryParams.append("idAgence", authData?.idAgence);
                }                
            }else{
                return;
            }

            const response = await axiosInstance.get(`${Materiel}/GetMaterielsListe?${queryParams}`,{ cancelToken });
            const resp = await response.data;
            setMateriels(resp.data);
            setFiltredMateriels(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            // setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const getAgences = async () => {
        try {
            if (authData?.role != null && authData?.role === "admin") {
                const response = await axiosInstance.get(`${Agence}`);
                const resp = await response.data;
                setAgences(resp);
            } else {
                const queryParams = new URLSearchParams({
                    idAgence: authData.idAgence
                });
                const response = await axiosInstance.get(`${Agence}?${queryParams}`);
                const resp = await response.data;
                formik1.setValues((prevValues) => ({
                    ...prevValues,
                    idAgence: authData.idAgence
                }));
                setAgences(resp);
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getMateriels(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getMateriels(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getMateriels(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);

    return (
        <>
            <div className="container material-section">
                <div className="affectation-sec wrap-all">
                    <Link href="#" className="utilisateur-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter matériel
                        </div>
                    </Link>
                    <div className="group-elements">
                        <div className="voir-elements">
                            {/* <p>voir</p> */}
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            {/* <p>Rechercher</p> */}
                            <input
                                type="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredMateriels?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>N° serie</th>
                                    <th>ID</th>
                                    <th>Type</th>
                                    <th>Statut</th>
                                    {authData?.role != null && authData?.role === "admin" && <th>Agence</th>}
                                    <th>Modifier</th>
                                    <th>Supprimer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredMateriels?.map((row) => (
                                    <tr key={row.idMateriel}>
                                        <td>{row.libelle}</td>
                                        <td>{row.numeroSerie}</td>
                                        <td>{row.idCompte}</td>
                                        <td>{
                                            row.type === "ecran" ? <>Écran</> :
                                                row.type === "borne tactile" ? <>Borne tactile</> :
                                                    null
                                        }</td>
                                        <td>{
                                            row.statut === "actif" ? <>Actif</> :
                                                row.statut === "inactif" ? <>Inactif</> :
                                                    null
                                        }</td>
                                        {authData?.role != null && authData?.role === "admin" && <td>{row.libelleAgence}</td>}
                                        <td className="txt-center" onClick={() => showHideUpdateModal(true, row.idMateriel, row.libelle, row.numeroSerie, row.type, row.statut, row.idAgence, row.idCompte)}>
                                            <span className="material-symbols-outlined">edit
                                            </span></td>
                                        <td className="txt-center" onClick={() => handleConfirmDelete(row.idMateriel)}>
                                            <span className="material-symbols-outlined">delete</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {/* <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Link
                                key={i + 1}
                                to="#"
                                className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Link>
                        ))}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                            disabled={((pageNumber + 1) > totalPages) ? true : false}
                        >&raquo;</Link>
                    </div> */}
                    {filtredMateriels?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>

            </div>
            {showAddModal && <div className="popcard-add" id="ajouter-materiel">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" d="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Ajouter matériel</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <label >Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelle"
                                            name="libelle"
                                            style={(formik1.touched.libelle && formik1.errors.libelle) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.libelle}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.libelle && formik1.errors.libelle ? (
                                            <div className="invalid-feedback">{formik1.errors.libelle}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one">
                                        <label >N° série</label>
                                        <input type="text"
                                            id="numeroSerie"
                                            name="numeroSerie"
                                            placeholder=""
                                            defaultValue={formik1.values.numeroSerie}
                                            onChange={formik1.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Agence<span className='requiredField'>*</span></label>
                                        <select
                                            id="idAgence"
                                            name="idAgence"
                                            value={formik1.values.idAgence}
                                            onChange={formik1.handleChange}
                                            style={(formik1.touched.idAgence && formik1.errors.idAgence) ? { borderColor: "red" } : null}
                                        >
                                            {authData?.role != null && authData?.role === "admin" && <option value="">Veuillez sélectionner</option>}
                                            {agences.map((agence) => (
                                                <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idAgence && formik1.errors.idAgence ? (
                                            <div className="invalid-feedback">{formik1.errors.idAgence}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label >Type<span className='requiredField'>*</span></label>
                                        <select
                                            id="type"
                                            name="type"
                                            style={(formik1.touched.type && formik1.errors.type) ? { borderColor: "red" } : null}
                                            value={formik1.values.type}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                            <option value="ecran">Écran</option>
                                            <option value="borne tactile">Borne tactile</option>
                                        </select>
                                        {formik1.touched.type && formik1.errors.type ? (
                                            <div className="invalid-feedback">{formik1.errors.type}</div>
                                        ) : null}
                                    </div>

                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statut"
                                            name="statut"
                                            value={formik1.values.statut}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            {showUpdateModal && <div className="popcard-add" id="modifier-materiel">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" d="close-vehc" to="#" onClick={() => showHideUpdateModal(false, '', '', '', '', '', '', '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Modifier matériel</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik2.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one">
                                        <label >Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelleUpdate"
                                            name="libelleUpdate"
                                            style={(formik2.touched.libelleUpdate && formik2.errors.libelleUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.libelleUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.libelleUpdate && formik2.errors.libelleUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.libelleUpdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one">
                                        <label >N° série</label>
                                        <input type="text"
                                            id="numeroSerieUpdate"
                                            name="numeroSerieUpdate"
                                            placeholder=""
                                            defaultValue={formik2.values.numeroSerieUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Agence<span className='requiredField'>*</span></label>
                                        <select
                                            id="idAgenceUpdate"
                                            name="idAgenceUpdate"
                                            value={formik2.values.idAgenceUpdate}
                                            onChange={formik2.handleChange}
                                            style={(formik2.touched.idAgenceUpdate && formik2.errors.idAgenceUpdate) ? { borderColor: "red" } : null}
                                        >
                                            {authData?.role != null && authData?.role === "admin" && <option value="">Veuillez sélectionner</option>}
                                            {agences.map((agence) => (
                                                <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                            ))}
                                        </select>
                                        {formik2.touched.idAgenceUpdate && formik2.errors.idAgenceUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.idAgenceUpdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one-half">
                                        <label >Type<span className='requiredField'>*</span></label>
                                        <select
                                            id="typeUpdate"
                                            name="typeUpdate"
                                            style={(formik2.touched.typeUpdate && formik2.errors.typeUpdate) ? { borderColor: "red" } : null}
                                            value={formik2.values.typeUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="" defaultValue={true}>Veuillez sélectionner</option>
                                            <option value="ecran">Écran</option>
                                            <option value="borne tactile">Borne tactile</option>
                                        </select>
                                        {formik2.touched.typeUpdate && formik2.errors.typeUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.typeUpdate}</div>
                                        ) : null}
                                    </div>

                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statutUpdate"
                                            name="statutUpdate"
                                            value={formik2.values.statutUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer ce matériel ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer le matériel"
            />
        </>

    );
}

export default Materiels;
