import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { degrees, grayscale, PDFDocument, PDFFont, rgb, StandardFonts } from 'pdf-lib';
import qrCode from 'qrcode';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import Pagination from '../../components/pagination/Pagination';
import { axiosInstance, Agence, Stock } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';

const StockParams = () => {
    const { authData } = useContext(AuthContext);
    const [agences, setAgences] = useState([]);

    const [stocks, setStocks] = useState([]);
    const [filtredStocks, setFiltredStocks] = useState([]);
    const [boxs, setBoxs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [idStock, setIdStock] = useState('');
    const [idStockDelete, setIdStockDelete] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredStocks].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredStocks(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = stocks.slice(startIndex, endIndex);

        setFiltredStocks(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Ajouter un stock
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
        getAgences();
    };

    const formik1 = useFormik({
        initialValues: {
            prefix: '',
            idAgence: '',
            nbrBox: '',
        },
        validationSchema: Yup.object({
            prefix: Yup.string()
                .required('Champ obligatoire'),
            idAgence: Yup.string()
                .required('Champ obligatoire'),
            nbrBox: Yup.number()
                .required('Champ obligatoire')
                .min(1, "Minimum 1"),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    prefix: values.prefix,
                    nbrBox: values.nbrBox,
                    idAgence: values.idAgence,
                    idCompteCrud: authData.idCompte
                };
                //console.log(values)
                const response = await axiosInstance.post(Stock, postData);
                CustomToast("Le stock a été créé avec succès", 'success');
                getListeStock(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Imprimer
    const showHidePrintModal = (val, idStock) => {
        setShowPrintModal(val);
        setIdStock(idStock)
        if (val) {
            getBoxs(idStock);
        }
    };

    //Supprimer

    //Supprimer  un Utilisateur
    const handleConfirmDelete = (idStockDelete) => {
        setIdStockDelete(idStockDelete);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Stock}/DeleteStock/${idStockDelete}`);
            //getTypesDepenses();
            setIdStockDelete('');
            CustomToast("Le stock a été supprimé avec succès", 'success');
            getListeStock(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Stock introuvable", 'error');
            } else if (error.response?.status === 400) {
                CustomToast("Le stock ne peut pas être supprimé car au moins un colis est associé.", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data

    const getListeStock = async (page, newPerPage) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            // setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }
            // if (authData?.role != null && authData?.role !== "admin") {
            //     queryParams.append("idAgence", authData.idAgence);
            // }
            if (authData?.role != null  && authData?.role !== "") {
                if (authData?.role !== "admin") {
                    queryParams.append("idAgence", authData?.idAgence);
                }                
            }else{
                return;
            }
            const response = await axiosInstance.get(`${Stock}/GetListeStock?${queryParams}`);
            const resp = await response.data;
            setStocks(resp.data);
            setFiltredStocks(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            // setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const getAgences = async () => {
        try {
            if (authData?.role != null && authData?.role === "admin") {
                const response = await axiosInstance.get(`${Agence}`);
                const resp = await response.data;
                setAgences(resp);
            } else {
                const queryParams = new URLSearchParams({
                    idAgence: authData.idAgence
                });

                const response = await axiosInstance.get(`${Agence}?${queryParams}`);
                const resp = await response.data;
                formik1.setValues((prevValues) => ({
                    ...prevValues,
                    idAgence: authData.idAgence
                }));
                setAgences(resp);
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    const getBoxs = async (idStockP) => {
        try {
            const response = await axiosInstance.get(`${Stock}/GetBoxsByIdStock/${idStockP}`);
            const resp = await response.data;
            setBoxs(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Imprimer etiquettes

    const printEtiquette1 = async (libelle) => {
        // Créer un nouvel objet PDFDocument
        let qrCodeText = libelle;
        const pdfDoc = await PDFDocument.create();
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // Générer le QR code
        const qrCodeOptions = {
            type: 'image/png',
            rendererOpts: {
                quality: 0.5,
            },
            color: {
                dark: '#000', // Blue dots
                light: '#0000', // Transparent background
            },
            errorCorrectionLevel: 'H',
            margin: 0,
        };

        const qrCodeDataUrl = await qrCode.toDataURL(libelle, qrCodeOptions);
        // Convertir l'image du QR code en bytes
        const qrCodeBytes = Uint8Array.from(atob(qrCodeDataUrl.split(',')[1]), (c) => c.charCodeAt(0));

        // Créer une nouvelle page au format 6,0 cm × 4,0 cm
        const page = pdfDoc.addPage([60, 40]);

        // Ajouter l'image du QR code à la page PDF
        const image = await pdfDoc.embedPng(qrCodeBytes);

        page.drawImage(image, {
            x: 5,
            y: 15,
            width: 20,
            height: 20,
        });

        page.drawText(libelle, {//Inserer un text
            x: 5,
            y: 10,
            size: 4,
            color: rgb(0, 0, 0),
            //rotate: degrees(-90),
        });

        // Enregistrez le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');

    };

    const printEtiquetteMultiple = async (qrCodeDataArray, textSup = "") => {
        // Créer un nouvel objet PDFDocument
        const pdfDoc = await PDFDocument.create();
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        for (const qrCodeData of qrCodeDataArray) {
            // Générer le QR code pour chaque objet dans le tableau
            const qrCodeOptions = {
                type: 'image/png',
                rendererOpts: {
                    quality: 0.5,
                },
                color: {
                    dark: '#000', // Blue dots
                    light: '#0000', // Transparent background
                },
                errorCorrectionLevel: 'H',
                margin: 0,
            };
            const qrCodeText = `${qrCodeData.libelle}`;
            const qrCodeDataUrl = await qrCode.toDataURL(qrCodeText, qrCodeOptions);
            const qrCodeBytes = Uint8Array.from(atob(qrCodeDataUrl.split(',')[1]), (c) => c.charCodeAt(0));

            // Créer une nouvelle page au format 6 cm × 4 cm
            const page = pdfDoc.addPage([60, 40]);

            // Ajouter l'image du QR code à la page PDF
            const image = await pdfDoc.embedPng(qrCodeBytes);

            page.drawImage(image, {
                x: 5,
                y: 15,
                width: 20,
                height: 20,
            });

            page.drawText(qrCodeText, {//Inserer un text
                x: 5,
                y: 10,
                size: 4,
                color: rgb(0, 0, 0),
                //rotate: degrees(-90),
            });
        }

        // Enregistrez le PDF dans un nouveau tableau de bytes
        const modifiedPdfBytes = await pdfDoc.save();

        // Créer un objet Blob à partir des bytes du PDF
        const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

        // Créer une URL de données pour le Blob
        const pdfUrl = URL.createObjectURL(blob);

        // Ouvrir le PDF dans une nouvelle fenêtre ou un nouvel onglet
        window.open(pdfUrl, '_blank');

    };

    useEffect(() => {
        //Load Data
        getListeStock(pageNumber, pageSize);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize, debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getListeStock(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);

    return (
        <>
            <div className="container Paramétrage-stock-section">
                <div className="affectation-sec wrap-all">
                    <Link href="#ajouter-stock" className="affectation-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter stock
                        </div>
                    </Link>
                    <div className="group-elements">
                        <div className="voir-elements">
                            {/* <p>voir</p> */}
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            {/* <p>Rechercher</p> */}
                            <input
                                type="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredStocks?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    {authData?.role != null && authData?.role === "admin" && <td>Agence</td>}
                                    <th>Prefix</th>
                                    <th>Imprimer</th>
                                    <th>Supprimer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredStocks?.map((row) => (
                                    <tr key={row.idStock}>
                                        {authData?.role != null && authData?.role === "admin" && <td>{row.libelleAgence}</td>}
                                        <td>{row.prefix}</td>
                                        <td><Link to="#" onClick={() => showHidePrintModal(true, row.idStock)}>
                                            <span className="material-symbols-outlined">
                                                print</span></Link></td>
                                        <td><Link to="#" onClick={() => handleConfirmDelete(row.idStock)}>
                                            <span className="material-symbols-outlined danger">
                                                delete</span></Link></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {/* <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Link
                                key={i + 1}
                                to="#"
                                className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Link>
                        ))}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                            disabled={((pageNumber + 1) > totalPages) ? true : false}
                        >&raquo;</Link>
                    </div> */}
                    {filtredStocks?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>
                {showAddModal && <div className="popcard-add" id="ajouter-stock">
                    <div className="popcard-add_inside">
                        <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                        <h2 className="popcard-add_inside-title"></h2>
                        <div className="popcard-add_inside-form">
                            <form onSubmit={formik1.handleSubmit} >
                                <div className="wrap-info">
                                    <div className="wrap-row">
                                        <div className="wrap-one-full">
                                            <label >Agence<span className='requiredField'>*</span></label>
                                            <select
                                                id="idAgence"
                                                name="idAgence"
                                                value={formik1.values.idAgence}
                                                onChange={formik1.handleChange}
                                                style={(formik1.touched.idAgence && formik1.errors.idAgence) ? { borderColor: "red" } : null}
                                            >
                                                {authData?.role != null && authData?.role === "admin" && <option value="">Veuillez sélectionner</option>}
                                                {agences.map((agence) => (
                                                    <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                                ))}
                                            </select>
                                            {formik1.touched.idAgence && formik1.errors.idAgence ? (
                                                <div className="invalid-feedback">{formik1.errors.idAgence}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one">
                                            <label>Prefix<span className='requiredField'>*</span></label>
                                            <input type="text"
                                                id="prefix"
                                                name="prefix"
                                                style={(formik1.touched.prefix && formik1.errors.prefix) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik1.values.prefix}
                                                onChange={formik1.handleChange}
                                            />
                                            {formik1.touched.prefix && formik1.errors.prefix ? (
                                                <div className="invalid-feedback">{formik1.errors.prefix}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="wrap-row">
                                        <div className="wrap-one">
                                            <label>Nombre de box<span className='requiredField'>*</span></label>
                                            <input
                                                type="number"
                                                id="nbrBox"
                                                name="nbrBox"
                                                style={(formik1.touched.nbrBox && formik1.errors.nbrBox) ? { borderColor: "red" } : null}
                                                placeholder=""
                                                defaultValue={formik1.values.nbrBox}
                                                onChange={formik1.handleChange}
                                            />
                                            {formik1.touched.nbrBox && formik1.errors.nbrBox ? (
                                                <div className="invalid-feedback">{formik1.errors.nbrBox}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="popcard-operation-pickup-details_inside-zone">
                                    <div className="buttons">
                                        <input type="submit" value="Enregistrer" className="submit-btn submit-btn-green" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>}
                {showPrintModal && <div className="popcard-add" id="print-stock">
                    <div className="popcard-add_inside">
                        <Link className="popcard-add_inside-close" id="close-vehc" to="#" onClick={() => showHidePrintModal(false, '')}>&#10006;</Link>
                        <h2 className="popcard-add_inside-title"></h2>
                        <div className="popcard-add_inside-form">
                            <div className="wrap-info">
                                {boxs?.length > 0 && <div className="table-wrap scroll-height mb1">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Libellé box</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {boxs?.map((row) => (
                                                <tr key={row.idBox}>
                                                    <td>{row.libelle}</td>
                                                    <td><Link to="#" onClick={() => printEtiquette1(row.libelle)}>
                                                        <span className="material-symbols-outlined danger">
                                                            print</span></Link></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>}
                            </div>

                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <input type="submit" value="Imprimer" className="submit-btn submit-btn-red" onClick={() => printEtiquetteMultiple(boxs)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer ce stock ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer le stock"
            />
        </>
    );
}

export default StockParams;
