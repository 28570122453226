import {HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";

function isConnectionConnecting(connection) {
    return (
        connection.state === HubConnectionState.Connected ||
        connection.state === HubConnectionState.Reconnecting ||
        connection.state === HubConnectionState.Connecting
    );
}

function createConnection(url) {
    const connection  = new HubConnectionBuilder()
    .withUrl(url, { withCredentials: true })
    .configureLogging(LogLevel.None)
    .withAutomaticReconnect(true)
    .build();

    connection.start().catch(err => console.error(err));

    return connection;
}

function joinGroup(connection, groupName) {
    if (isConnectionConnecting(connection)) {
        connection.invoke("JoinGroup", groupName)
            .then(() => console.log(`Joined group: ${groupName}`))
            .catch(err => console.error(`Failed to join group: ${groupName}`, err));
    } else {
        console.error("Connection is not in a valid state to join group.");
    }
}

export { isConnectionConnecting, createConnection, joinGroup };