import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './login.css'
import { CustomToast } from '../../components/Toast/CustomToast';
import { axiosInstance, Authentification,AppHub } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const Login = () => {
    const { login } = useContext(AuthContext);

    const [isAuthLoading, setAuthLoading] = useState(false);
    const navigate = useNavigate();


    const { handleChange, values, handleSubmit, touched, errors } = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required("Username obligatoire"),
            password: Yup.string()
                .required("Mot de passe obligatoire")
        }),
        onSubmit: async (values) => {
            try {
                const response = await axiosInstance.post(`${Authentification}/authenticateuser`, values);

                let authInfo = JSON.stringify(response.data);
                localStorage.setItem('authData', authInfo);

                login(JSON.parse(authInfo));
                setAuthLoading(true);
                localStorage.setItem('menuShow', '');
                CustomToast(`Bienvenue, ${values.username}!`, 'success');
                localStorage.removeItem('numTicketActuel');
                localStorage.removeItem('idTicketActuel');
                
                if (response.data.role === "agent guichet") {
                    navigate('/appguichet', { replace: true });
                }else if(response.data.role === "gestionnaire stock") {
                    navigate('/demandes', { replace: true });
                }
                else if(response.data.role === "materiel") {
                    navigate('/ecran', { replace: true });
                } else{
                    navigate('/empty', { replace: true });
                }
            } catch (error) {
                //setAuthLoading(false);
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data.statutResponse === "User not found - Incorrect username") {
                    CustomToast("Username ou mot de passe incorrectes", 'error');
                } else if (error.response?.status === 400 && error.response?.data.statutResponse === "User not found - Incorrect password") {
                    CustomToast("Username ou mot de passe incorrectes", 'error');
                } else if (error.response?.status === 400 && error.response?.data.statutResponse === "User is not authorized") {
                    CustomToast("L'utilisateur n'est pas autorisé à se connecter", 'error');
                } else if (error.response?.status === 400 && error.response?.data.statutResponse === "Agence n'existe pas") {
                    CustomToast("Agence n'existe pas", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    return (
        <div className="container_login">
            <div className="l-form">
                <form className="form" onSubmit={handleSubmit}>
                    <img src="./img/yalidine-logo.png" alt="" />

                    <div className="form__div">
                        <input
                            id="username"
                            name="username"
                            type="text"
                            className="form__input"
                            placeholder=" "
                            defaultValue={values.username}
                            onChange={handleChange}
                        />
                        <label className="form__label">Nom d'utilisateur</label>
                    </div>

                    <div className="form__div">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            className="form__input"
                            placeholder=" "
                            defaultValue={values.password}
                            onChange={handleChange}
                        />
                        <label className="form__label">Mot de passe</label>
                    </div>

                    <button type="submit"
                        className="form__button"
                    >Se connecter</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
