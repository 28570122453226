import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import Pagination from '../../components/pagination/Pagination';
import { axiosInstance, createCancelTokenSource, Guichet, Agence, FileAttenteParams } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';
const Guichets = () => {

    const { authData } = useContext(AuthContext);
    const [guichets, setGuichets] = useState([]);
    const [filtredGuichets, setFiltredGuichets] = useState([]);
    const [agences, setAgences] = useState([]);
    const [fileAttentes, setFileAttentes] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idGuichetDelete, setIdGuichetsDelete] = useState('');
    const [GuichetsUpdate, setIdGuichetsUpdate] = useState('');

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredGuichets].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredGuichets(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = guichets.slice(startIndex, endIndex);

        setFiltredGuichets(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Ajouter guichet
    const showHideAddModal = (val) => {
        setShowAddModal(val);
        formik1.resetForm();
        formik1.values.statut = 'actif';
        getAgences();
        if (authData?.role != null && authData?.role !== "admin") {
            getFileAttentes(authData.idAgence)
        }
    };

    const formik1 = useFormik({
        initialValues: {
            idAgence: '',
            numero: '',
            idFileAttente: '',
            statut: 'actif',
            libelle: '',
        },
        validationSchema: Yup.object({
            idAgence: Yup.string()
                .required('Champ obligatoire'),
            idFileAttente: Yup.string()
                .required('Champ obligatoire'),
            numero: Yup.number()
                .required('Champ obligatoire'),
            statut: Yup.string()
                .required('Champ obligatoire'),
            libelle: Yup.string()
                .required('Champ obligatoire'),
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idAgence: values.idAgence,
                    libelle: values.libelle,
                    numero: values.numero.toString(),
                    statut: values.statut,
                    idFileAttente: values.idFileAttente,
                    idCompteCrud: authData.idCompte
                };
                //console.log(values)
                const response = await axiosInstance.post(Guichet, postData);
                CustomToast("Le guichet a été créé avec succès", 'success');
                getGuichets(pageNumber, pageSize);
                setShowAddModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un guichet avec le même nom existe déjà.") {
                    CustomToast("Un guichet avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un guichet avec le même numéro existe déjà.") {
                    CustomToast("Un guichet avec le même numéro existe déjà.", 'error');
                }  else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Modifier guichet
    const showHideUpdateModal = (val, idGuichet, idAgence, idCompte, libelle, statut, idFileAttente, numero) => {
        setShowUpdateModal(val);
        formik2.resetForm();
        if (val) {
            getAgences();
            if (authData?.role != null && authData?.role !== "admin") {
                getFileAttentes(idAgence)
            }
            formik2.setValues((prevValues) => ({
                ...prevValues,
                idGuichetUpdate: idGuichet,
                idCompteUpdate: idCompte,
                idAgenceUpdate: idAgence,
                libelleUpdate: libelle,
                numeroUpdate: numero,
                statutUpdate: statut || 'actif',
                idFileAttenteUpdate: idFileAttente
            }));
        }
    };

    const formik2 = useFormik({
        initialValues: {
            idGuichetUpdate: '',
            idAgenceUpdate: '',
            idCompteUpdate: '',
            idFileAttenteUpdate: '',
            statutUpdate: 'actif',
            libelleUpdate: '',
            numeroUpdate: '',
        },
        validationSchema: Yup.object({
            idAgenceUpdate: Yup.string()
                .required('Champ obligatoire'),
            idFileAttenteUpdate: Yup.string()
                .required('Champ obligatoire'),
            statutUpdate: Yup.string()
                .required('Champ obligatoire'),
                numeroUpdate: Yup.number()
                .required('Champ obligatoire'),
            libelleUpdate: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idGuichet: values.idGuichetUpdate,
                    idAgence: values.idAgenceUpdate,
                    idCompte: values.idCompteUpdate,
                    libelle: values.libelleUpdate,
                    numero: values.numeroUpdate.toString(),
                    statut: values.statutUpdate,
                    idFileAttente: values.idFileAttenteUpdate,
                    idCompteCrud: authData.idCompte
                };

                const response = await axiosInstance.put(Guichet, postData);
                CustomToast("Le guichet a été modifié avec succès", 'success');
                getGuichets(pageNumber, pageSize);
                setShowUpdateModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un guichet avec le même nom existe déjà.") {
                    CustomToast("Un guichet avec le même nom existe déjà.", 'error');
                } else if (error.response?.status === 409 && error.response?.data === "Un guichet avec le même numéro existe déjà.") {
                    CustomToast("Un guichet avec le même numéro existe déjà.", 'error');
                }  else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Supprimer un guicht
    const handleConfirmDelete = (idGuichet) => {
        setIdGuichetsDelete(idGuichet);
        setConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${Guichet}/${idGuichetDelete}`);
            //getTypesDepenses();
            setIdGuichetsDelete('');
            CustomToast("Le guichet a été supprimé avec succès", 'success');
            getGuichets(1, pageSize);
            setConfirmDialogOpen(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Guichet introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else if (error.response?.status === 400) {
                CustomToast("Suppression impossible, guichet déjà utilisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpen(false);
    };

    //Load data

    const getGuichets = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            // setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            // if (authData?.role != null && authData?.role !== "admin") {
            //     if(authData.idAgence == null || authData.idAgence === ""){
            //         return;
            //     }
            //     queryParams.append("idAgence", authData.idAgence);
            // }

            if (authData?.role != null  && authData?.role !== "") {
                if (authData?.role !== "admin") {
                    queryParams.append("idAgence", authData?.idAgence);
                }                
            }else{
                return;
            }

            const response = await axiosInstance.get(`${Guichet}/GetGuichetsListe?${queryParams}`,{ cancelToken });
            const resp = await response.data;
            setGuichets(resp.data);
            setFiltredGuichets(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            // setLoading(false);
        }finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const getAgences = async () => {
        try {
            if (authData?.role != null && authData?.role === "admin") {
                const response = await axiosInstance.get(`${Agence}`);
                const resp = await response.data;
                setAgences(resp);
            } else {
                const queryParams = new URLSearchParams({
                    idAgence: authData.idAgence
                });
                const response = await axiosInstance.get(`${Agence}?${queryParams}`);
                const resp = await response.data;
                formik1.setValues((prevValues) => ({
                    ...prevValues,
                    idAgence: authData.idAgence
                }));
                setAgences(resp);
            }

        }
        catch (err) {
            console.log(err);
        }
    }

    const getFileAttentes = async (idAgence) => {
        try {
            if(idAgence===null || idAgence===""){
                return;
            }
            const response = await axiosInstance.get(`${FileAttenteParams}/GetFileAttenteIdAgence/${idAgence}`);
            const resp = await response.data;
            setFileAttentes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getGuichets(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getGuichets(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getGuichets(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);

    useEffect(() => {
        //Load Data
        getFileAttentes(formik1.values.idAgence);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, formik1.values.idAgence]);

    useEffect(() => {
        //Load Data
        getFileAttentes(formik2.values.idAgenceUpdate);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, formik2.values.idAgenceUpdate]);

    return (
        <>
            <div className="container guichet-section">
                <div className="affectation-sec wrap-all">
                    <Link href="#" className="utilisateur-add color-primary-2 decoration-none" onClick={() => showHideAddModal(true)}>
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter guichet
                        </div>
                    </Link>
                    <div className="group-elements">
                        <div className="voir-elements">
                            {/* <p>voir</p> */}
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            {/* <p>Rechercher</p> */}
                            <input
                                type="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredGuichets?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    {authData?.role != null && authData?.role === "admin" && <th>Agence</th>}
                                    <th>Nom</th>
                                    <th>Numéro</th>
                                    <th>File d'attente</th>
                                    <th>Statut</th>
                                    <th>Modifier</th>
                                    <th>Supprimer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filtredGuichets?.map((row) => (
                                    <tr key={row.idGuichet}>
                                        {authData?.role != null && authData?.role === "admin" && <td>{row.libelleAgence}</td>}
                                        <td>{row.libelle}</td>
                                        <td>{row.numero}</td>
                                        <td>{row.libelleFileAttente}</td>
                                        <td>{
                                            row.statut === "actif" ? <>Actif</> :
                                                row.statut === "inactif" ? <>Inactif</> :
                                                    null
                                        }</td>
                                        <td className="txt-center" onClick={() => showHideUpdateModal(true, row.idGuichet, row.idAgence, row.idCompte, row.libelle, row.statut, row.idFileAttente, row.numero)}>
                                            <span className="material-symbols-outlined">edit
                                            </span></td>
                                        <td className="txt-center" onClick={() => handleConfirmDelete(row.idGuichet)}>
                                            <span className="material-symbols-outlined">delete</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {/* <div className="pagination">
                        <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                            className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                            disabled={(pageNumber === 1) ? true : false}
                        >&laquo;</Link>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Link
                                key={i + 1}
                                to="#"
                                className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Link>
                        ))}
                        <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                            className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                            disabled={((pageNumber + 1) > totalPages) ? true : false}
                        >&raquo;</Link>
                    </div> */}
                    {filtredGuichets?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>

            </div>
            {showAddModal && <div className="popcard-add" id="ajouter-utilisateur">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" d="close-vehc" to="#" onClick={() => showHideAddModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Ajouter guichet</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik1.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Agence<span className='requiredField'>*</span></label>
                                        <select
                                            id="idAgence"
                                            name="idAgence"
                                            value={formik1.values.idAgence}
                                            onChange={formik1.handleChange}
                                            style={(formik1.touched.idAgence && formik1.errors.idAgence) ? { borderColor: "red" } : null}
                                        >
                                            {authData?.role != null && authData?.role === "admin" && <option value="">Veuillez sélectionner</option>}
                                            {agences.map((agence) => (
                                                <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idAgence && formik1.errors.idAgence ? (
                                            <div className="invalid-feedback">{formik1.errors.idAgence}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one">
                                        <label >Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelle"
                                            name="libelle"
                                            style={(formik1.touched.libelle && formik1.errors.libelle) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.libelle}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.libelle && formik1.errors.libelle ? (
                                            <div className="invalid-feedback">{formik1.errors.libelle}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statut"
                                            name="statut"
                                            value={formik1.values.statut}
                                            onChange={formik1.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                    <div className="wrap-one-half">
                                        <label>Numéro<span className='requiredField'>*</span></label>
                                        <input type="number"
                                            id="numero"
                                            name="numero"
                                            style={(formik1.touched.numero && formik1.errors.numero) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik1.values.numero}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.numero && formik1.errors.numero ? (
                                            <div className="invalid-feedback">{formik1.errors.numero}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >File d'attente<span className='requiredField'>*</span></label>
                                        <select
                                            id="idFileAttente"
                                            name="idFileAttente"
                                            value={formik1.values.idFileAttente}
                                            onChange={formik1.handleChange}
                                            style={(formik1.touched.idFileAttente && formik1.errors.idFileAttente) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {fileAttentes.map((fa) => (
                                                <option key={fa.idFileAttente} value={fa.idFileAttente}>{fa.libelle}</option>
                                            ))}
                                        </select>
                                        {formik1.touched.idFileAttente && formik1.errors.idFileAttente ? (
                                            <div className="invalid-feedback">{formik1.errors.idFileAttente}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            {showUpdateModal && <div className="popcard-add" id="ajouter-utilisateur">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" d="close-vehc" to="#" onClick={() => showHideUpdateModal(false)}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">Modifier guichet</h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik2.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >Agence<span className='requiredField'>*</span></label>
                                        <select
                                            id="idAgenceUpdate"
                                            name="idAgenceUpdate"
                                            value={formik2.values.idAgenceUpdate}
                                            onChange={formik2.handleChange}
                                            style={(formik2.touched.idAgenceUpdate && formik2.errors.idAgenceUpdate) ? { borderColor: "red" } : null}
                                        >
                                            {authData?.role != null && authData?.role === "admin" && <option value="">Veuillez sélectionner</option>}
                                            {agences.map((agence) => (
                                                <option key={agence.idAgence} value={agence.idAgence}>{agence.libelle}</option>
                                            ))}
                                        </select>
                                        {formik2.touched.idAgenceUpdate && formik2.errors.idAgenceUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.idAgenceUpdate}</div>
                                        ) : null}
                                    </div>
                                    <div className="wrap-one">
                                        <label >Nom<span className='requiredField'>*</span></label>
                                        <input type="text"
                                            id="libelleUpdate"
                                            name="libelleUpdate"
                                            style={(formik2.touched.libelleUpdate && formik2.errors.libelleUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.libelleUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.libelleUpdate && formik2.errors.libelleUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.libelleUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label>Statut<span className='requiredField'>*</span></label>
                                        <select
                                            id="statutUpdate"
                                            name="statutUpdate"
                                            value={formik2.values.statutUpdate}
                                            onChange={formik2.handleChange}
                                        >
                                            <option value="actif" defaultValue={true}>Actif</option>
                                            <option value="inactif">Inactif</option>
                                        </select>
                                    </div>
                                    <div className="wrap-one-half">
                                        <label >Numéro<span className='requiredField'>*</span></label>
                                        <input type="number"
                                            id="numeroUpdate"
                                            name="numeroUpdate"
                                            style={(formik2.touched.numeroUpdate && formik2.errors.numeroUpdate) ? { borderColor: "red" } : null}
                                            placeholder=""
                                            defaultValue={formik2.values.numeroUpdate}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.numeroUpdate && formik2.errors.numeroUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.numeroUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="wrap-row">
                                    <div className="wrap-one-half">
                                        <label >File d'attente<span className='requiredField'>*</span></label>
                                        <select
                                            id="idFileAttenteUpdate"
                                            name="idFileAttenteUpdate"
                                            value={formik2.values.idFileAttenteUpdate}
                                            onChange={formik2.handleChange}
                                            style={(formik2.touched.idFileAttenteUpdate && formik2.errors.idFileAttenteUpdate) ? { borderColor: "red" } : null}
                                        >
                                            <option value="">Veuillez sélectionner</option>
                                            {fileAttentes.map((fa) => (
                                                <option key={fa.idFileAttente} value={fa.idFileAttente}>{fa.libelle}</option>
                                            ))}
                                        </select>
                                        {formik2.touched.idFileAttenteUpdate && formik2.errors.idFileAttenteUpdate ? (
                                            <div className="invalid-feedback">{formik2.errors.idFileAttenteUpdate}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            <ConfirmationDialog
                open={confirmDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleDelete}
                titleMessage="Confirmer la suppression"
                bodyMessage="Voulez-vous vraiment supprimer ce guichet ? Toutes les données seront définitivement perdues."
                btnCancelText="Annuler"
                btnConfirmText="Supprimer le guichet"
            />
        </>

    );
}

export default Guichets;
