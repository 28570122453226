import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomToast } from '../../components/Toast/CustomToast';
import ConfirmationDialog from '../../components/confirmation/ConfirmationDialog';
import Pagination from '../../components/pagination/Pagination';
import { axiosInstance, createCancelTokenSource, Agence } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';
import useDebounce from '../../hooks/useDebounce';

const Agences = () => {

    const { authData } = useContext(AuthContext);
    const [agences, setAgences] = useState([]);
    const [filtredAgences, setFiltredAgences] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [idAgenceDelete, setIdAgenceDelete] = useState('');
    const [AgenceUpdate, setAgenceUpdate] = useState('');

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [showDesactiverModal, setShowDesactiverModal] = useState(false);

    const [search, setSearch] = useState('');
    const debouncedSearchText = useDebounce(search, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredAgences].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredAgences(sortedTable);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
        const startIndex = (page - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedData = agences.slice(startIndex, endIndex);

        setFiltredAgences(paginatedData);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    //Load data

    const getAgences = async (page, newPerPage, cancelToken) => {
        if (isFetching) return;
        setIsFetching(true);
        setLoading(true);
        try {
            // setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (debouncedSearchText != null) {
                queryParams.append("text", debouncedSearchText);
            }

            const response = await axiosInstance.get(`${Agence}/GetAgencesListe?${queryParams}`, { cancelToken });
            const resp = await response.data;
            setAgences(resp.data);
            setFiltredAgences(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            // setLoading(false);
        } finally {
            setLoading(false);
            setIsFetching(false);
        }
    }

    const GetAgencesFromApi = async () => {
        try {
            const response = await axiosInstance.get(`${Agence}/GetAgencesFromApi/${authData.idCompte}`);
            // console.log(response)
            if (response?.status === 200 && response?.data === "New data") {
                getAgences(1, pageSize);
            }
            CustomToast("Les agences ont été importés avec succès", 'success');
        }
        catch (err) {
            console.log(err);
        }
    }

    //Desactiver une agence
    const showHideDesactiverModal = (val, idAgence, statut) => {
        setShowDesactiverModal(val);
        formik4.resetForm();
        if (val) {
            formik4.setValues({
                idAgence: idAgence,
                statut: statut,
            });
        }
    };

    const formik4 = useFormik({
        initialValues: {
            idAgence: '',
            statut: ''
        },
        onSubmit: async (values) => {
            try {
                const postData = {
                    idAgence: values.idAgence,
                    idCompteCrud: authData.idCompte
                };
                //console.log(postData);
                const response = await axiosInstance.put(`${Agence}/DesactiverAgence`, postData);
                if (values.statut === "actif") {
                    CustomToast("L'agence a été désactivée avec succès", 'success');
                } else {
                    CustomToast("L'agence a été activée avec succès", 'success');
                }

                getAgences(pageNumber, pageSize);
                setShowDesactiverModal(false);
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });



    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getAgences(pageNumber, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [authData, pageNumber, pageSize]);

    useEffect(() => {
        //Load Data
        const source = createCancelTokenSource();
        getAgences(1, pageSize, source.token);

        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
            source.cancel('Operation canceled by the user.');
        };
    }, [debouncedSearchText]);

    // useEffect(() => {
    //     //Load Data
    //     getAgences(1, pageSize);

    //     return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData, debouncedSearchText]);

    return (
        <>
            <div className="container agence-section">
                <div className="affectation-sec wrap-all">
                    <Link href="#" className="utilisateur-add color-primary-2 decoration-none" onClick={() => GetAgencesFromApi()}>
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Récupérer les agences à partir de l'API Yalidine
                        </div>
                    </Link>
                    <div className="group-elements">
                        <div className="voir-elements">
                            {/* <p>voir</p> */}
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="search-element">
                            {/* <p>Rechercher</p> */}
                            <input
                                type="search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    {filtredAgences?.length > 0 && <div className="table-wrap">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Nom sur ticket</th>
                                    <th>Wilaya</th>
                                    <th>Commune</th>
                                    <th>Adresse</th>
                                    <th>Manager</th>
                                    {/* <th>Désactiver</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {filtredAgences?.map((row) => (
                                    <tr key={row.idAgence}>
                                        <td>{row.libelle}</td>
                                        <td>{row.libelleAgenceTicket}</td>
                                        <td>{row.libelleWilaya}</td>
                                        <td>{row.libelleCommune}</td>
                                        <td>{row.adresse}</td>
                                        <td>{row.manager}</td>
                                        {/* <td className="txt-center" onClick={() => showHideDesactiverModal(true, row.idFileAttente)}>
                                            <span className="material-symbols-outlined">lock
                                            </span>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {/* <div className="pagination">
                    <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                        className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                        disabled={(pageNumber === 1) ? true : false}
                    >&laquo;</Link>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <Link
                            key={i + 1}
                            to="#"
                            className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </Link>
                    ))}
                    <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                        className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                        disabled={((pageNumber + 1) > totalPages) ? true : false}
                    >&raquo;</Link>
                </div> */}
                    {filtredAgences?.length > 0 && <Pagination
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                        pageSize={pageSize}
                        totalItems={totalItems}
                    />}
                </div>

            </div>

            {showDesactiverModal && <div className="popcard-add" id="initialiser-Paramétrage">
                <div className="popcard-add_inside">
                    <Link className="popcard-add_inside-close" id="close-init" to="#" onClick={() => showHideDesactiverModal(false, '')}>&#10006;</Link>
                    <h2 className="popcard-add_inside-title">
                        {formik4.values.statut==="actif"?"Désactiver l'agence":"Activer l'agence"}
                        </h2>
                    <div className="popcard-add_inside-form">
                        <form onSubmit={formik4.handleSubmit} >
                            <div className="wrap-info">
                                <div className="wrap-row">
                                    <strong>
                                        {formik4.values.statut==="actif"?"Êtes-vous sûr de vouloir désactiver l'agence ? ":"Êtes-vous sûr de vouloir activer l'agence ? "}
                                    </strong>
                                </div>
                            </div>
                            <div className="popcard-operation-pickup-details_inside-zone">
                                <div className="buttons">
                                    <button type="submit" className="submit-btn submit-btn-red" onClick={() => showHideDesactiverModal(false, '')}>Annuler</button>
                                    <button type="submit" className="submit-btn submit-btn-green">Enregistrer</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default Agences;
